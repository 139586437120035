<template>
  <router-link
    :to="{
      name: 'DetalleVehiculo',
      params: {
        vehiculo: datoVehiculo.patente,
        datos: datoVehiculo,
        lista_vehiculos: vehiculos,
      },
    }"
    style="text-decoration: none"
  >
    <v-hover v-slot:default="{ hover }">
      <v-card
        class="ml-2 mr-3 mt-4 mb-4 theme--light zoom"
        width="320"
        height="450"
        :elevation="hover ? 18 : 3"
        :class="hover ? 'borde' : ''"
      >
        <v-img
          v-if="
            datoVehiculo.imagenesMinis &&
            datoVehiculo.imagenesMinis.length !== 0
          "
          :src="datoVehiculo.imagenesMinis[0]"
          height="200px"
        >
          <v-row justify="start">
            <div class="label label-primary">{{ datoVehiculo.marca }}</div>
          </v-row>
        </v-img>

        <v-img
          v-if="
            datoVehiculo.imagenesMinis &&
            datoVehiculo.imagenesMinis.length === 0
          "
          src="../assets/sinImagenes2.webp"
          height="200px"
        ></v-img>
        <v-row justify="center" align="center">
          <v-card-title
            style="height: 50px"
            v-if="
              datoVehiculo.anio &&
              datoVehiculo.marca &&
              datoVehiculo.modelo
            "
          >
            <h2 class="marca text-center">
              {{ datoVehiculo.anio }} {{ datoVehiculo.marca }}
              {{ datoVehiculo.modelo }}
            </h2>
          </v-card-title>

          <!-- <v-card-title
            style="height: 50px"
            v-if="
              datoVehiculo.anio &&
              datoVehiculo.marca &&
              datoVehiculo.modelo &&
              datoVehiculo.anio.toString().length +
                datoVehiculo.marca.length +
                datoVehiculo.modelo.length >=
                27 &&
              datoVehiculo.anio.toString().length +
                datoVehiculo.marca.length +
                datoVehiculo.modelo.length <
                30
            "
          >
            <h2 class="marca text-center">
              {{ datoVehiculo.anio.toString() }} {{ datoVehiculo.marca }}
              {{
                datoVehiculo.modelo.substring(
                  0,
                  datoVehiculo.modelo.length - 6
                )
              }}...
            </h2>
          </v-card-title> -->

          <!-- <v-card-title
            style="height: 50px"
            v-if="
              datoVehiculo.anio &&
              datoVehiculo.marca &&
              datoVehiculo.modelo &&
              datoVehiculo.anio.toString().length +
                datoVehiculo.marca.length +
                datoVehiculo.modelo.length >=
                30
            "
          >
            <h2 class="marca text-center">
              {{ datoVehiculo.anio.toString() }} {{ datoVehiculo.marca }}
              {{
                datoVehiculo.modelo.substring(
                  0,
                  datoVehiculo.modelo.length - 5
                )
              }}...
            </h2>
          </v-card-title> -->
        </v-row>

        <v-row justify="center" align="center" style="margin-top: -20px">
          <v-card-subtitle>
            <div class="descripcion">
              {{ datoVehiculo.transmision }} | {{ datoVehiculo.km }} KM
              {{ datoVehiculo.motor }}
            </div>
          </v-card-subtitle>
        </v-row>
        <v-row justify="center" align="center" style="margin-top: 10px">
          <v-card-title class="precio"> PRECIO DEL CONCESIONARIO </v-card-title>
          <v-card-title class="precio" style="margin-top: -30px">
            ${{ Intl.NumberFormat().format(datoVehiculo.precio) }}
          </v-card-title>
        </v-row>
        <v-row justify="center" align="center">
          <v-card-actions>
            <router-link
              :to="{
                name: 'DetalleVehiculo',
                params: {
                  vehiculo: datoVehiculo.patente,
                  datos: datoVehiculo,
                  lista_vehiculos: vehiculos,
                },
              }"
              style="text-decoration: none"
              target="_blank"
            >
              <v-btn color="#1B75BA">
                <span style="color: snow">Mas Informacion</span>
              </v-btn>
            </router-link>
          </v-card-actions>
        </v-row>
      </v-card>
    </v-hover>
  </router-link>
</template>

<script>
export default {
  props: ["datoVehiculo", "vehiculos"],

  created() {
    this.guardarListaLocalStorage(this.vehiculos);
  },

  methods: {
    guardarListaLocalStorage(lista) {
      localStorage.setItem("det_lista_vehiculo", JSON.stringify(lista));
    },
  },
};
</script>

<style>
.precio {
  font-size: 18px;
  color: #1b75ba;
  font-weight: 400;
}
.marca {
  margin-top: 0px;
  margin-bottom: 3px;
  font-size: 17px;
  text-transform: uppercase;
  color: #373a3c;
  font-weight: 400;
}

.descripcion {
  color: #afafaf;
  font-size: 15px;
  font-weight: 300;
}

/* .v-btn__content{
    color: snow;
} */

.label {
  position: absolute;
  top: 0px;
  z-index: 10;
  margin: 20px 0px 0px 20px;
  text-transform: uppercase;
  font-size: 13px;
}

.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.label-primary {
  background-color: #337ab7;
}

.borde {
  background-color: #337ab7;
}

.theme--dark.v-card {
  background-color: #fff;
  color: #1e1e1e;
}

.zoom {
  transition: transform 0.2s;
}


.zoom:hover {
  transform: scale(1.03);
}
</style>
