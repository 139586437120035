<template>
  <div>
    <v-app>
      <Header />
      <v-main>
        <v-container>
          <v-row>
            <v-col cols="12">
              <h1 class="text-center" style="margin-top: -60px">
                {{ vehiculoDato.anio }} - {{ vehiculoDato.modeloFull }}

              </h1>
              <h2 v-if="screen >= 1024" class="text-center" style="margin-top: -10px">
                ({{ vehiculoDato.transmision }}) | Km {{ vehiculoDato.km }}
              </h2>
              <h2 v-if="screen < 1024" class="text-center" style="margin-top: -10px">
                ({{ vehiculoDato.transmision }}) | {{ vehiculoDato.km }}
              </h2>
            </v-col>
            <v-col cols="12" sm="12" md="7" lg="7" xl="7">
              <div>
                <v-carousel show-arrows-on-hover cycle height="500" hide-delimiter-background
                  delimiter-icon="fas fa-minus" v-if="
                    vehiculoDato.ImagenesGrandes &&
                    vehiculoDato.ImagenesGrandes.length > 0
                  ">
                  <v-carousel-item v-for="(item, i) in vehiculoDato.ImagenesGrandes" :key="i">
                    <v-img height="500" :src="item"> </v-img>
                  </v-carousel-item>
                </v-carousel>
                <v-carousel :show-arrows="false" cycle height="500" hide-delimiter-background
                  delimiter-icon="fas fa-minus" v-if="
                    vehiculoDato.ImagenesGrandes &&
                    vehiculoDato.ImagenesGrandes.length == 0
                  ">
                  <v-carousel-item src="../assets/sinImagenes2.webp">
                  </v-carousel-item>
                </v-carousel>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="5" lg="5" xl="5">
              <v-card class="mx-auto" :height="tarjeta" elevation="4">
                <h1 class="text-center formatoPalabras precio">
                  PRECIO DEL CONCESIONARIO ${{
                    Intl.NumberFormat().format(vehiculoDato.precio)
                  }}
                </h1>

                <v-list>
                  <hr style="color: #737070" />
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon size="20" color="#808080" style="margin-top: -4px; margin-right: 7px">
                        fas fa-car
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="formatoPalabras" style="color: #808080; font-size: 20px">
                        <b>
                          <span class="atr" style="color: black">
                            Patente
                          </span>
                        </b>
                        {{ vehiculoDato.patente }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <hr style="color: #737070" />
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon size="17" color="#808080" style="margin-top: -4px; margin-right: 7px">
                        fas fa-tachometer-alt
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="formatoPalabras" style="color: #808080; font-size: 20px">
                        <b>
                          <span class="atr" style="color: black">
                            Kilometros
                          </span>
                        </b>
                        {{ vehiculoDato.km }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <hr style="color: #737070" />
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon size="17" color="#808080" style="margin-top: -4px; margin-right: 7px">
                        mdi-car-shift-pattern
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="formatoPalabras" style="color: #808080; font-size: 20px">
                        <b>
                          <span class="atr" style="color: black">
                            Transmision
                          </span>
                        </b>
                        {{ vehiculoDato.transmision }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <hr style="color: #737070" />
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon size="17" color="#808080" style="margin-top: -4px; margin-right: 7px">
                        mdi-engine
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="formatoPalabras" style="color: #808080; font-size: 20px">
                        <b>
                          <span class="atr" style="color: black"> Motor </span>
                        </b>
                        {{ vehiculoDato.motor }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <hr style="color: #737070" />
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon size="17" color="#808080" style="margin-top: -4px; margin-right: 7px">
                        fas fa-gas-pump
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="formatoPalabras" style="color: #808080; font-size: 20px">
                        <b>
                          <span class="atr" style="color: black">
                            Combustible
                          </span>
                        </b>
                        {{ vehiculoDato.combustible }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <hr style="color: #737070" />
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon size="17" color="#808080" style="margin-top: -4px; margin-right: 10px">
                        fas fa-tint
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="formatoPalabras" style="color: #808080; font-size: 20px">
                        <b>
                          <span class="atr" style="color: black"> Color </span>
                        </b>
                        {{ vehiculoDato.color }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <hr style="color: #737070" />
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon size="17" color="#808080" style="
                          margin-top: -4px;
                          margin-right: 7px;
                          margin-left: -4px;
                        ">
                        mdi-car-door
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="formatoPalabras" style="color: #808080; font-size: 20px">
                        <b>
                          <span class="atr" style="color: black">
                            Puertas
                          </span>
                        </b>
                        {{ vehiculoDato.puertas }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <!-- <hr style="color:#737070"> -->
              </v-card>
              <v-subheader style="font-size: 15px; margin-top: 10px">*Los precios expresados por unidad no incluyen
                gastos de
                transferencia ni administrativos</v-subheader>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" v-if="screen >= 1024">
              <h1>DETALLE DEL VEHICULO</h1>
              <p class="descripcion">{{ vehiculoDato.descripcion }}</p>
            </v-col>
            <v-col cols="12" v-if="screen < 1024">
              <h1 style="margin-top: 30px; font-size: 25px">
                DETALLE DEL VEHICULO
              </h1>
              <p>{{ vehiculoDato.descripcion }}</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h1 v-if="screen >= 1024">VEHICULOS SIMILARES</h1>
              <h1 v-if="screen < 1024" style="margin-top: 30px; font-size: 25px">
                VEHICULOS SIMILARES
              </h1>
              <v-carousel cycle interval="10000" hide-delimiters
                v-if="vehiculo_similares && vehiculo_similares.length > 0">
                <template v-for="(item, index) in vehiculo_similares">
                  <v-carousel-item v-if="(index + 1) % columns === 1 || columns === 1" :key="index">
                    <v-row style="height: 50%">
                      <template v-for="(n, i) in columns">
                        <template v-if="+index + i < vehiculo_similares.length">
                          <v-col :key="i">
                            <v-sheet v-if="+index + i < vehiculo_similares.length" height="120%" color="white">
                              <v-row align="center" justify="center">
                                <VehiculoCard :datoVehiculo="vehiculo_similares[+index + i]"
                                  :vehiculos="listaVehiculos" />
                              </v-row>
                            </v-sheet>
                          </v-col>
                        </template>
                      </template>
                    </v-row>
                  </v-carousel-item>
                </template>
              </v-carousel>
            </v-col>
          </v-row>
        </v-container>

        <v-btn class="cotizacion" v-scroll="onScroll1" v-show="fab1" fixed bottom right color="#9c1313"
          style="z-index: 20" @click="cotizacion = true">
          <span style="color: white">Solicitar Cotizacion</span>
        </v-btn>
        <v-btn class="cotizacion-2" v-scroll="onScroll1" v-if="screen > 760" v-show="fab1" fixed top right
          color="#9c1313" style="margin-top: 70px; margin-right: 60px" @click="cotizacion = true">
          <span style="color: white">Solicitar Cotizacion</span>
        </v-btn>
        <SolicitarCotizacion :dialog.sync="cotizacion" :vehiculo="vehiculoDato" />
      </v-main>
      <Footer />
      <v-btn v-scroll="onScroll2" v-show="fab2" fab dark fixed bottom left color="#9c1313" @click="toTop">
        <v-icon>fas fa-arrow-circle-up</v-icon>
      </v-btn>
    </v-app>
    <Loader :loader="loader" />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import VehiculoCard from "../components/VehiculoCard.vue";
import SolicitarCotizacion from "../components/SolicitarCotizacion.vue";
import { mapActions, mapState } from "vuex";
import {
  getMarcasUsados,
  getVehiculoPorPatente,
  getVehiculosEnAlta,
} from "../services/vehiculos";
import Loader from "../components/Loader.vue";

export default {
  components: {
    Header,
    Footer,
    VehiculoCard,
    SolicitarCotizacion,
    Loader,
  },
  data() {
    return {
      //   vehiculoDato: this.$route.params.datos,
      vehiculoDato: this.$route.params.datos || {},
      loader: false,
      listaVehiculos: [],
      vehiculo_similares: [],
      fab1: true,
      fab2: false,
      cotizacion: false,
      screen: screen.width,
      patente: "",
    };
  },

  methods: {
    ...mapActions(["getVehiculo"]),
    onScroll1(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab1 = top >= 0;
    },

    vehiculosSimilares() {
      this.vehiculo_similares = [];
      for (let i = 0; i < this.listaVehiculos.length; i++) {
        if (
          (this.listaVehiculos[i].marca == this.vehiculoDato.marca ||
            this.listaVehiculos[i].anio == this.vehiculoDato.anio ||
            this.listaVehiculos[i].motor == this.vehiculoDato.motor) &&
          this.listaVehiculos[i].id != this.vehiculoDato.id
        ) {
          this.listaVehiculos[i].ImagenesGrandes =
            this.listaVehiculos[i].UrlImagenesGrandes;
          this.listaVehiculos[i].imagenesMinis =
            this.listaVehiculos[i].UrlImagenesMini;

          this.vehiculo_similares.push(this.listaVehiculos[i]);
        }
      }
      this.toTop();
    },

    onScroll2(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab2 = top > 20;
    },

    toTop() {
      this.$vuetify.goTo(0);
    },

    cargarLocalStorage() {
      if (localStorage.getItem("det_vehiculo")) {
        let aux_vehiculo = JSON.parse(localStorage.getItem("det_vehiculo"));
        if (aux_vehiculo.patente == this.$route.params.vehiculo) {
          this.vehiculoDato = JSON.parse(localStorage.getItem("det_vehiculo"));
        } else {
          localStorage.setItem(
            "det_vehiculo",
            JSON.stringify(this.vehiculoDato)
          );
        }
      } else {
        localStorage.setItem(
          "det_vehiculo",
          JSON.stringify(this.$route.params.datos)
        );
      }
      if (localStorage.getItem("det_lista_vehiculo")) {
        this.listaVehiculos = JSON.parse(
          localStorage.getItem("det_lista_vehiculo")
        );
      } else {
        localStorage.setItem(
          "det_lista_vehiculo",
          JSON.stringify(this.$route.params.lista_vehiculos)
        );
      }
    },

    comparaDatos() {
      if (
        (this.vehiculo.precio != this.vehiculoDato.precio ||
          this.vehiculo.km != this.vehiculoDato.km) &&
        this.vehiculo.id == this.vehiculoDato.id
      ) {
        this.vehiculoDato.precio = this.vehiculo.precio;
        this.vehiculoDato.km = this.vehiculo.km;
        let i = 0;
        while (
          i < this.listaVehiculos.length &&
          this.listaVehiculos[i].id != this.vehiculo.id
        ) {
          i++;
        }
        if (this.listaVehiculos[i].id == this.vehiculo.id) {
          this.listaVehiculos[i].precio = this.vehiculo.precio;
          this.listaVehiculos[i].km = this.vehiculo.km;
          // localStorage.setItem(
          //   "det_lista_vehiculo",
          //   JSON.stringify(this.listaVehiculos)
          // );
        }
      } else {
      }
    },

    vehiducloUndefined() {
      if (this.vehiculoDato == undefined || this.listaVehiculos == undefined) {
        this.listaVehiculos = JSON.parse(
          localStorage.getItem("det_lista_vehiculo")
        );
        let i = 0;
        while (
          i < this.listaVehiculos.length &&
          this.listaVehiculos[i].patente != this.$route.params.vehiculo
        ) {
          i++;
        }
        if (this.listaVehiculos[i].patente == this.$route.params.vehiculo) {
          this.vehiculoDato = this.listaVehiculos[i];
          localStorage.setItem(
            "det_vehiculo",
            JSON.stringify(this.listaVehiculos[i])
          );
        }
      }
    },

    getPatenteEnUrl() {
      // Obtén la URL actual
      const url = window.location.href;

      // Divide la URL por '/' y obtén el último segmento
      const parts = url.split("/");
      const id = parts[parts.length - 1];

      this.patente = id;
    },
  },

  computed: {
    ...mapState(["vehiculo"]),
    columns() {
      if (this.$vuetify.breakpoint.xl) {
        return 4;
      }

      if (this.$vuetify.breakpoint.lg) {
        return 3;
      }

      if (this.$vuetify.breakpoint.md) {
        return 2;
      }

      return 1;
    },

    titulo() {
      return screen.width < 1024 ? "1.5em" : "2em";
    },

    tarjeta() {
      return screen.width < 1024 ? "550" : "500";
    },
  },

  async created() {
    this.loader = true;

    this.getPatenteEnUrl();
    this.vehiculoDato = await getVehiculoPorPatente(this.patente);
    this.vehiculoDato.ImagenesGrandes = this.vehiculoDato.UrlImagenesGrandes;
    this.vehiculoDato.imagenesMinis = this.vehiculoDato.UrlImagenesMini;
    localStorage.setItem("det_vehiculo", JSON.stringify(this.vehiculoDato));
    this.listaVehiculos = await getVehiculosEnAlta();

    this.listaVehiculos.forEach((vehiculo) => {
      vehiculo.ImagenesGrandes = vehiculo.UrlImagenesGrandes;
      vehiculo.imagenesMinis = vehiculo.UrlImagenesMini;
    });
    await this.vehiducloUndefined(); // Para cuando se abre en pestaña nueva , los atributos se vuelven indefinidos
    // await this.cargarLocalStorage();
    await this.vehiculosSimilares();
    await this.getVehiculo(this.vehiculoDato.id);
    await this.comparaDatos();
    this.loader = false;
  },

  watch: {
    $route: {
      deep: true,
      async handler(to, from) {
        if (to.name == from.name && to.path !== from.path) {
          this.vehiculoDato = this.$route.params.datos;
          await this.getVehiculo(this.vehiculoDato.id);
          this.comparaDatos();
          this.vehiculosSimilares();
          this.cargarLocalStorage();
          window.location.reload();
        }
      },
    },
  },
};
</script>

<style scoped>
.formatoPalabras {
  font-family: "Poppins", sans-serif;
}

.atr {
  min-width: 200px;
  display: inline-block;
  font-size: 20px;
}

.precio {
  color: #1b75ba !important;
  font-size: 30px !important;
  font-weight: 400 !important;
}

.v-toolbar__content {
  justify-content: center;
}

.theme--dark.v-card {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
}

.descripcion {
  font-family: Louis, sans-serif !important;
  margin-top: 15px;
  color: #4e4d4d;
  font-weight: 500 !important;
  font-size: 18px;
}

.cotizacion {
  /* font-size: 10px; */
  margin-top: 80px;
}

@media (width < 600px) {
  .cotizacion-2 {
    display: none;
  }
}

@media (width > 600px) {
  .cotizacion {
    display: none;
  }
}
</style>
