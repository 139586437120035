<template>
  <v-row justify="center" >   
    <v-dialog :value ="dialog"  fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Imagenes</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn  icon dark @click="closeDialog">
                 <v-icon>fas fa-times</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list three-line subheader>
          <v-list-item>
            <v-list-item-content class="centrar">
              <v-list-item-title align="center" class="subtitulo">Imagenes</v-list-item-title>
              <div  v-if="this.imagensGrandesRef.length === 0">
                  <v-row align="center" justify="center">
                            <v-carousel hide-delimiters class="mt-4 ancho" 
                                    v-if="this.imagensGrandesRef.length === 0"
                            >
                                <v-carousel-item
                                    src="../assets/sinImagenes2.webp"
                                >
                                </v-carousel-item>
                            </v-carousel>                
                    </v-row>
              </div>
                    <!-- <v-carousel hide-delimiters class="mt-4 ancho" 
                            v-if="this.imagensGrandesRef.length === 0"
                    >
                        <v-carousel-item
                            src="../assets/sinImagenes2.webp"
                        >
                        </v-carousel-item>
                    </v-carousel> -->
                    <div v-if="this.imagensGrandesRef.length > 0">
                            <v-row v-if="this.imagensGrandesRef.length > 0" align="center" justify="center">
                                    <v-col v-for="(img,i) in imagensGrandesRef" :key="i" cols="12" md="3" sm="3" >
                                                <v-card 
                                                    flat
                                                    max-width="265"
                                                    max-height = "250"
                                                    style="margin-top:20px; margin-bottom:20px"
                                                >
                                                        <v-img
                                                            height="200"
                                                            :src="img.url"      
                                                        ></v-img>
                                                        <v-row align="center" justify="center">
                                                            <v-card-actions>
                                                                <v-select
                                                                    v-model="img.orden"
                                                                    :items="items"
                                                                    label="Elejir orden"
                                                                    @change="actualizarItems(img.orden)"
                                                                    
                                                                >
                                                                    <template v-slot:no-data>
                                                                        <v-list-item>
                                                                        <v-list-item-content>
                                                                            <v-list-item-title>
                                                                            No results matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd> to create a new one
                                                                            </v-list-item-title>
                                                                        </v-list-item-content>
                                                                        </v-list-item>
                                                                    </template>
                                                                </v-select>
                                                            </v-card-actions>
                                                        </v-row>
                                                </v-card>
                                    </v-col>
                        </v-row>
                        <v-row align="center" justify="center">
                            <v-btn color="primary" style="width: 140px;" :loading="loading" @click="save()">Guardar</v-btn>
                        </v-row>
                    </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
    <v-row justify="center">
              <v-dialog
                  v-model="elegido"
                  persistent max-width="350">
                  <v-card>
                      <v-card-title class="text-center" style="font-size: 15px !important; color:#616067d1 !important ;font-weight: 400;justify-content:center">Ya fue elegida esa posicion , por favor elija otra o vuelva reordenar</v-card-title>
                      <v-card-actions style="justify-content: center;">
                              <v-btn color="primary" text  @click="elegido=false" >Aceptar</v-btn>
                      </v-card-actions>
                  </v-card>

              </v-dialog>
              
        </v-row>
     <v-row justify="center">
              <v-dialog
                  v-model="guardado"
                  persistent max-width="350">
                  <v-card>
                      <v-card-title class="text-center" style="font-size: 15px !important; color:#616067d1 !important ;font-weight: 400;justify-content:center">Se guardo el orden con exito</v-card-title>
                      <v-card-actions style="justify-content: center;">
                              <v-btn color="primary" text  @click="cerrar()" >Aceptar</v-btn>
                      </v-card-actions>
                  </v-card>

              </v-dialog>
              
        </v-row>
  </v-row>
</template>

<script>
import {storage , db} from '../firebase'
import Swal from 'sweetalert2'
export default {
    props :{
        dialog: {
            type: Boolean,
            required: true
        },
        id:{
            type: String
        },
        multiple:{
          type:Boolean,
          default:true
        },
    },
    data() {
        return {
            imagensGrandesRef : [],
            items: [],
            selected: [],
            search: null,
            listaOrdem:[],
            elegido : false,
            loading : false,
            guardado:false
        }
    },

    created() {
        this.getImagenes()
        
    },

    computed: {
      columns() {
          if (this.$vuetify.breakpoint.xl) {
            return 4;
          }

          if (this.$vuetify.breakpoint.lg) {
            return 3;
          }

          if (this.$vuetify.breakpoint.md) {
            return 2;
          }

          return 1;
    },
    },

    methods: {
        closeDialog(){
            this.$emit("update:dialog", false) 
        },

        async getImagenes(){
           this.imagensGrandesRef = []
            let ImagenesGrandes = await db.collection('vehiculos').doc(this.id).get()
            let lista = ImagenesGrandes.data().UrlImagenesGrandes
            for (let i = 0; i < lista.length; i++) {
                let data = {
                    url : lista[i],
                    orden : i+1
                }
                this.imagensGrandesRef.push(data)
            }
            for (let i = 0; i < this.imagensGrandesRef.length; i++) {
                let j=0
                let b = 0
                if(this.items.length == 0 ){
                     this.items.push(i+1)    
                }
                else{
                    while(j < this.items.length && b == 0) {
                        if(this.items[j] == i+1){
                            b = 1 // para saber si ya esta en la lista
                        }
                        else{
                            b = 0
                        }
                        j++
                    }
                    if(b == 0){
                        this.items.push(i+1)       
                    }
                }
            }
            
        },

        ordenarImagenes(){
            let lista = this.imagensGrandesRef.slice()
            lista.sort(function(a,b){
            if (parseInt(a.orden) > parseInt(b.orden)) {
              return 1;
            }
            if (parseInt(a.orden) < parseInt(b.orden)) {
              return -1;
            }
            return 0;
          })
          return lista
        },

        async save(){
            this.loading = true
            let ordenado = this.ordenarImagenes()
            let lista_ordenada = []
            for (let i = 0; i < ordenado.length; i++) {
                lista_ordenada.push(ordenado[i].url)
            }

            
            await  db.collection('vehiculos').doc(this.id).update({
                    UrlImagenesGrandes :  lista_ordenada
            })
            .then(res =>{
                    this.loading = false
                    this.guardado = true
            })
        },

        actualizarItems(item){
            if(this.selected.length == 0) {
                this.selected.push(item)
            }
            else{
                let i = 0
                let b = 0
                while ( i < this.selected.length && b == 0){
                    if (this.selected[i] == item){
                        b = 1
                    }
                    i++
                }
                if(b == 0 ){
                    this.selected.push(item)
                }
                else{
                    this.elegido =true
                }
            }
        },

        cerrar(){
            this.guardado = false
            this.closeDialog()
        }
        
    },
    watch: {
        dialog:function(){
            if(this.dialog){
                this.getImagenes()
            }
        }
    },


}
</script>

<style>

.ancho{
    width: 800px  !important;
}

.centrar {
    justify-content: center !important;
}

.orden{
    font-size: 18px;
    margin-top: 10px;
    color: #616161;
}

.subtitulo{
    font-size: 2.25rem;
    line-height: 1.5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: bold;
    color: #2196F3;
}
.margen-carusel{
      margin-top: 100px;
    /* margin-bottom: 10px; */
    margin-left: 20px;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

</style>