<template>
    <v-container>
      <v-card style="background-color:#e4e3ec14" flat>
        <h1 class="titulo" >{{this.vehiculo.patente}}  {{this.marca}}  {{this.vehiculo.modelo}}</h1>
        <v-container>
          <v-row >
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field  label="Patente" outlined readonly v-model="this.vehiculo.patente"  ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field label="Marca" outlined readonly v-model="marca" ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field label="Modelo" outlined readonly v-model="this.vehiculo.modelo" ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field label="Km" outlined readonly v-model="this.vehiculo.km" ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field label="Año" outlined readonly v-model="this.vehiculo.anio" ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field label="Ubicacion" outlined readonly v-model="this.vehiculo.ubicacion"  ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-select
                          v-model="estado" 
                          :items=itemsEstado
                          label="Estado"
                          outlined
                          readonly
                          
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field label="Color" outlined readonly v-model="this.vehiculo.color"  ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field label="Transmision" outlined readonly v-model="this.vehiculo.transmision"  ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field  label="Motor" outlined readonly v-model="this.vehiculo.motor" ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field label="Combustible" outlined readonly v-model="this.vehiculo.combustible" ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field label="Puertas" outlined readonly v-model="this.vehiculo.puertas"  ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field label="Precio" outlined readonly v-model="this.vehiculo.precio"  ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="8">
                          <v-text-field label="Descripcion del vehículo" outlined readonly v-model="this.vehiculo.descripcion"  ></v-text-field>
                      </v-col>
          </v-row>
        </v-container>
      </v-card>
      <v-row  align="center" justify="center" style="margin-top: 15px;margin-bottom: 10px;">
        <v-btn  @click="dialogimagen= true" color="gris" style="width: 174px;"> 
                <v-icon small class="mr-2" color="black">far fa-images</v-icon>
                          Ver Imagenes
        </v-btn>
        <v-btn  color="primary" class="ml-3" style="width: 174px;" @click ="volver()"> 
                          Volver 
        </v-btn>
        
        </v-row>
      <CaruselImagenes  :dialog.sync = "dialogimagen" :id.sync= "id" />        
    </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CaruselImagenes from '../components/CaruselImagenes'
import  {db}  from '../firebase'
import router from '../router'
export default {
  components:{
    CaruselImagenes
  },
  // props : ['valor'],
    data() {
        return {
            id : this.$route.params.id,
            //valor: this.$route.params.valor,
            datosVehiculo:'',
            estado:'',
            marca:'',
            itemsEstado:['Ok','Baja'],
            dialogimagen : false
        }
    },
   async created() {
        await this.getVehiculo(this.id)
        await this.getEstado(this.vehiculo.estadoRef.id)
        await this.getMarca(this.vehiculo.marcaRef.id)
       
    },
    methods: {
      ...mapActions(['getVehiculo']),

     async getMarca(id){
      await db.collection('marcas').doc(id).get()
        .then(res =>{
            this.marca =  res.data().nombre
        })
    },

   async getEstado(id){
     await db.collection('estados').doc(id).get()
    .then(res =>{
        this.estado =  res.data().nombre
    })
   },

   volver(){
     router.push('/vehiculo') 
   }
    },
    computed: {
      ...mapState(['vehiculo']),
    },
    
}
</script>

<style scoped>
.titulo{
    color:#1867c0;
    text-align: center;
}
</style>