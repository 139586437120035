<template>
  <v-text-field
    v-model="valor"
    v-bind="properties"
    :label="label"
    :type="tipo"
    :placeholder="label"
    persistent-placeholder
    outlined
    dense
    @focus="desformatear()"
    @blur="formatear(); $emit('blur')"
    @change="set_monto(); $emit('change')"
    @keypress="enterKey"
  ></v-text-field>
</template>

<script>
import { format_money } from '../../util/utils'

export default {
  data () {
    return {
      valor: null,
      tipo: 'tel'
    }
  },
  created () {
    this.formatear()
  },
  props: {
    value: Number,
    label: {
      type: String,
      default: ''
    },
    properties: {
      type: Object,
      default: function() {
        return {}
      }
    },
  },
  computed: {
    monto: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    monto () {
      this.formatear()
    }
  },
  methods: {
    enterKey (e) {
      if (e.key == 'Enter') document.activeElement.blur()
    },
    set_monto () {
      if (this.tipo == 'number') {
        const valor_numerico = parseFloat(JSON.parse(JSON.stringify(this.valor)))
        this.monto = Number.isNaN(valor_numerico) ? null : parseFloat(valor_numerico.toFixed(2))
      }
    },
    desformatear () {
      this.tipo = 'number'
      const valor_numerico = parseFloat(this.valor.replaceAll('.', '').replace(',', '.').replace(' ', ''))
      this.valor = Number.isNaN(valor_numerico) ? null : parseFloat(valor_numerico.toFixed(2))
      document.activeElement.click()
    },
    formatear () {
      this.tipo = 'tel'
      this.valor = format_money(this.monto).replace('$', '').trim()
    }
  }
}
</script>