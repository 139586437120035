<template>
  <div>
    <v-container fluid v-if="(this.mensajes.length === 0 && this.vacio === 0)">
      <v-row align="center" justify="center" style="height: 600px;">
        <Progreso />
      </v-row>
    </v-container>
    <div class="mt-4 ml-4 mr-4 mb-4" v-if="mensajes.length != 0 || this.vacio === 1">
      <h1 class="titulo">MENSAJES</h1>
      <h2><v-icon color="black">fas fa-filter</v-icon> Filtro</h2>
      <v-card>
        <v-form>
          <v-container>
            <v-row>
              <v-col cols="12" md="4">
                <v-select v-model="filtro.estado" :items="items" label="Estado">
                </v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model="filtro.fechaInicio" type="date" label="Fecha Desde">
                </v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model="filtro.fechaFin" type="date" label="Fecha Hasta">
                </v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model="filtro.apellido" label="Apellido">
                </v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model="filtro.nombre" label="Nombre">
                </v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-btn block color="primary" :loading="loading" style="margin-top: 14px;" @click="buscar(filtro)">
                  <v-icon class="ml-2">fas fa-search</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
    </div>
    <template class="mt-4 ml-4 mr-4 mb-4" v-if="mensajes.length != 0 || this.vacio === 1">
      <v-data-table :headers="headers" :items="filasFiltradas" class="elevation-1" style="margin: 17px ;">
        <template v-slot:[`item.accion`]="{ item }">
          <v-btn small color="success" v-if="item.estado === 'Pendiente'" class="mr-2" @click="Respondido(item)">
            <v-icon small>
              fas fa-check
            </v-icon>
          </v-btn>
          <v-btn small @click="GuardarDatos(item)">
            <v-icon small color="black">
              fas fa-envelope-open-text
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-dialog v-model="dialog" max-width="1000px">
        <v-card>
          <v-toolbar dark color="primary" style="height: 53px;">
            <v-toolbar-title>Mensaje de : {{ datos.apellido }} {{ datos.nombre }} {{ datos.email }} Tel :
              {{ datos.telefono }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn icon dark small @click="dialog = false">
                <v-icon small>fas fa-times</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
            <v-container class="ml-1">
              <v-row>
                <v-col cols="12">
                  <h3>Mensaje</h3>
                  <br>
                  <v-card>
                    <v-card-text>
                      <p>{{ datos.comentario }}</p>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <h3>Observaciones</h3>
                  <br>
                  <v-card>
                    <v-card-text>
                      <v-textarea class="mx-2" v-model="datos.observacion" rows="4">
                      </v-textarea>
                    </v-card-text>
                  </v-card>

                </v-col>
                <v-col cols="12">

                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>

              <v-select class="ml-1" color="blue darken-1" :items="items" label="Estado" v-model="datos.estado" outlined
                dense>
              </v-select>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="save(datos)">Guardar</v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Progreso from '../components/Progreso'
import moment from 'moment'
import { db } from '../firebase'

export default {
  components: {
    Progreso
  },
  data() {
    return {
      filtro: {
        nombre: '',
        apellido: '',
        fechaInicio: '',
        fechaFin: '',
        estado: ''
      },
      mensajesfb: [],
      items: [
        'Respondido',
        'Pendiente'
      ],
      headers: [
        { text: 'Estado', value: 'estado' },
        { text: 'Fecha', value: 'fecha' },
        { text: 'Apellido', value: 'apellido' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Accion', value: 'accion', sortable: false }
      ],
      desserts: [],
      dialog: false,
      datos: {},
      loading: false,
      filas: [],
      vacio: 0,
      filasFiltradas: []

    }
  },
  methods: {

    async buscar(filtro) {
      await this.getMensajes()
      this.filasFiltradas = this.filas.filter(fila => {
        const fechaInicio = moment(filtro.fechaInicio); // Analizar la fecha de inicio con Moment.js
        const fechaFin = moment(filtro.fechaFin); // Analizar la fecha de fin con Moment.js
        const fechaFila = moment(fila.fecha, 'DD/MM/YYYY');
        return (filtro.estado === '' || filtro.estado === fila.estado) &&
          (filtro.nombre === '' || filtro.nombre === fila.nombre) &&
          (filtro.apellido === '' || filtro.apellido === fila.apellido) &&
          (!fechaInicio.isValid() || fechaFila.isSameOrAfter(fechaInicio, 'day')) &&
          (!fechaFin.isValid() || fechaFila.isSameOrBefore(fechaFin, 'day'));
      });
    },



    //     buscar(filtro) {
    //     this.filasFiltradas = this.filas.filter(fila => {
    //         const fechaInicio = moment(filtro.fechaInicio).format('DD/MM/YYYY'); // Analizar la fecha de inicio con Moment.js
    //         const fechaFin = moment(filtro.fechaFin).format('DD/MM/YYYY'); // Analizar la fecha de fin con Moment.js
    //         const fechaFila = moment(fila.fecha, 'DD/MM/YYYY')


    //         return (filtro.estado === '' || filtro.estado === fila.estado) &&
    //                (filtro.nombre === '' || filtro.nombre === fila.nombre) && 
    //                (filtro.apellido === '' || filtro.apellido === fila.apellido) &&
    //                (!fechaInicio.isValid() || fechaFila.isSameOrAfter(fechaInicio, 'day')) &&
    //                (!fechaFin.isValid() || fechaFila.isSameOrBefore(fechaFin, 'day'));
    //     });
    // },




    ...mapActions(['getMensajes', 'aplicarFiltro', 'EstadoRespondido', 'GuardarCambioMensaje']),
    filtradoAux(filtro) {
      // if(this.filtro.fechaInicio != '' && this.filasFiltradas.length == 0){
      //   return
      // }
      this.loading = true

      if (filtro.fechaInicio == '') {
        this.aplicarFiltro(filtro)
      }
      this.desserts = []
      this.mensajesFiltrado.forEach(mensaje => {
        const datos = {
          id: mensaje.id,
          estado: mensaje.estado,
          fecha: mensaje.fecha,
          apellido: mensaje.apellido,
          nombre: mensaje.nombre,
          email: mensaje.email,
          telefono: mensaje.telefono,
          comentario: mensaje.comentario,
          observacion: mensaje.observacion
        }
        this.desserts.push(datos)
        if (this.mensajesFiltrado.length === this.desserts.length) {
          this.filas = this.desserts
          let aux = {
            nombre: this.filtro.nombre,
            apellido: this.filtro.apellido,
            fechaInicio: moment(this.filtro.fechaInicio).format('DD/MM/YYYY'),
            fechaFin: moment(this.filtro.fechaFin).format('DD/MM/YYYY'),
            estado: this.filtro.estado
          }
          localStorage.setItem('mensajes', JSON.stringify(this.filas))
          // localStorage.setItem('filtro_mensaje',JSON.stringify(aux))
          this.cargarLocalStorage(this.filas, aux)

          this.filasFiltradas = this.filas
        }
      });
      if (this.mensajesFiltrado.length == 0) {
        this.filas = []
        let aux = {
          nombre: this.filtro.nombre,
          apellido: this.filtro.apellido,
          fechaInicio: this.filtro.fechaInicio,
          fechaFin: this.filtro.fechaFin,
          estado: this.filtro.estado
        }
        localStorage.setItem('mensajes', JSON.stringify(this.filas))
        localStorage.setItem('filtro_mensaje', JSON.stringify(aux))
        this.cargarLocalStorage(this.filas, aux)
        this.filasFiltradas = this.filas
      }
      this.loading = false
    },




    Respondido(item) {
      item.estado = 'Respondido'
      this.EstadoRespondido(item)
      let lista = JSON.parse(localStorage.getItem('mensajes'))
      lista = lista.map(mensaje => {
        if (mensaje.id === item.id) {
          return item
        }
        else {
          return mensaje
        }
      })
      localStorage.setItem('mensajes', JSON.stringify(lista))
      this.getMensajes()
    },


    GuardarDatos(item) {
      this.datos = { ...item }
      this.dialog = true

    },
    save(datos) {
      this.dialog = false
      try {
        this.GuardarCambioMensaje(datos)
        let lista = JSON.parse(localStorage.getItem('mensajes'))
        lista = lista.map(mensaje => {
          if (mensaje.id === datos.id) {
            return datos
          }
          else {
            return mensaje
          }
        })
        localStorage.setItem('mensajes', JSON.stringify(lista))
        this.filas = lista
        this.getMensajes()
      } catch (error) {
      }
    },

    async cargarLocalStorage(filas, aux) {
      if (localStorage.getItem('mensajes')) {
        this.filas = JSON.parse(localStorage.getItem('mensajes'))
      }
      else {
        localStorage.setItem('mensajes', JSON.stringify(filas))
      }
      if (localStorage.getItem('filtro_mensaje')) {
        let aux = JSON.parse(localStorage.getItem('filtro_mensaje'))
        this.filtro = aux
        // this.selectMarca = aux.marca.nombre
        // this.selectEstado = aux.estado.nombre
      }
      else {
        localStorage.setItem('filtro', JSON.stringify(aux))
      }
    },

    ActualizaDesserts() {
      this.desserts = []
      if (this.mensajesFiltrado.length !== 0) {
        this.loading = true
        this.mensajesFiltrado.forEach(mensaje => {
          const datos = {
            id: mensaje.id,
            estado: mensaje.estado,
            fecha: mensaje.fecha,
            apellido: mensaje.apellido,
            nombre: mensaje.nombre,
            email: mensaje.email,
            telefono: mensaje.telefono,
            comentario: mensaje.comentario,
            observacion: mensaje.observacion
          }
          this.desserts.push(datos)
          if (this.mensajesFiltrado.length === this.desserts.length) {
            this.filas = this.desserts
            localStorage.setItem('mensajes', JSON.stringify(this.filas))

            this.filasFiltradas = this.filas
          }
        });
        //this.filas = this.desserts
      }
    },

    vacio_mensajes() {
      if (this.mensajes.length == 0) {
        this.vacio = 1
      }
    }
  },
  computed: {
    ...mapState(['mensajesFiltrado', 'mensajes'])
  },
  created() {
    this.getMensajes()

    db.collection('mensajes').get()
        .then(res => {
          res.forEach(doc => {
            let mensaje = doc.data()
            mensaje.fecha = moment.unix(doc.data().fecha.seconds).format("DD/MM/YYYY")
            mensaje.id = doc.id
            if (mensaje.proviene == 'Dycar') {
              this.mensajesfb.push(mensaje)
            }
          })
          this.filas = this.mensajesfb
          this.filasFiltradas = this.filas
        })
    localStorage.setItem('mensajes', JSON.stringify(this.mensajes))
    this.cargarLocalStorage()
    this.vacio_mensajes()
  },
  watch: {


    mensajesFiltrado: function () {
      this.desserts = []
      this.mensajesFiltrado.forEach(mensaje => {
        const datos = {
          id: mensaje.id,
          estado: mensaje.estado,
          fecha: mensaje.fecha,
          apellido: mensaje.apellido,
          nombre: mensaje.nombre,
          email: mensaje.email,
          telefono: mensaje.telefono,
          comentario: mensaje.comentario,
          observacion: mensaje.observacion
        }
        this.desserts.push(datos)

      });
    },
  }

}
</script>

<style>
.titulo {
  color: #1867c0;
  text-align: center;
  font-size: 40px;
}
</style> 