<template>
  <v-container v-if="vehiculo != null">
    <h1 class="titulo">EDITAR</h1>
    <v-card style="background-color:#e4e3ec14" flat>
      <h1 class="titulo">{{ this.vehiculo.patente }} {{ this.marca }} {{ this.vehiculo.modelo }}</h1>
      <v-container>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-text-field label="Patente" outlined v-model="vehiculo.patente"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field label="Marca" outlined v-model="marca"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field label="Modelo" outlined v-model="vehiculo.modelo"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field label="Km" outlined v-model="vehiculo.km"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field label="Año" outlined v-model="vehiculo.anio"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field label="Ubicacion" outlined v-model="vehiculo.ubicacion"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-select v-model="estado" :items=itemsEstado outlined label="Estado"></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field label="Color" outlined v-model="vehiculo.color"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field label="Transmision" outlined v-model="vehiculo.transmision"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field label="Motor" outlined v-model="vehiculo.motor"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field label="Combustible" outlined v-model="vehiculo.combustible"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field label="Puertas" outlined v-model="vehiculo.puertas"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field label="Precio" outlined v-model="vehiculo.precio"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field label="Descripcion del vehículo" outlined v-model="vehiculo.descripcion"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-select
            v-model="vehiculo.empresa"
            :items="['RADA']"
            label="Empresa"
            attach
            outlined
            clearable
          ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-container>
      <v-row align="center" justify="center" style="margin-top: 8px;">
        <v-btn @click="dialogimagen = true" color="#79797d" style="width: 174px;">
          <v-icon small class="mr-2">far fa-images</v-icon>
          Ver Imagenes
        </v-btn>

        <v-btn @click="uploadDialog1 = true" color="info" class="ml-3" style="width: 174px;">
          <v-icon small class="mr-2">fas fa-plus</v-icon>
          Imagenes Mini
        </v-btn>
        <Upload :dialog.sync="uploadDialog1" :id.sync="id" :mini=true />
        <v-btn @click="uploadDialog2 = true" color="info" class="ml-3" style="width: 174px;">
          <v-icon small class="mr-2">fas fa-plus</v-icon>
          Imagenes
        </v-btn>
        <Upload :dialog.sync="uploadDialog2" :id.sync="id" :mini=false />
        <v-btn @click="dialogimagenordenar = true" color="#CCCCCC" class="ml-3" style="width: 194px;">
          <v-icon small class="mr-2">fas fa-sort </v-icon>
          Ordenar Imagenes
        </v-btn>
        <v-btn @click="dialogimagenelimina = true" color="error" class="ml-3" style="width: 194px;">
          <v-icon small class="mr-2">fas fa-trash-alt</v-icon>
          Eliminar Imagenes
        </v-btn>
      </v-row>
      <v-row align="center" justify="center" style="margin-top: 30px;margin-bottom: 10px;">
        <v-btn color="primary" style="width: 140px;" @click="guardar()" :loading="loading">Guardar</v-btn>
        <v-btn color="error" class="ml-2" style="width: 140px;" @click="cancelar()">Cancelar</v-btn>
      </v-row>
    </v-container>
    <template>
      <v-row justify="center">
        <v-dialog v-model="guardado" persistent max-width="350">
          <v-card>
            <v-card-title class="text-center"
              style="font-size: 15px !important; color:#616067d1 !important ;font-weight: 400;justify-content:center">Se
              guardaron los cambios exitosamente !!</v-card-title>
            <v-card-actions style="justify-content: center;">
              <v-btn color="primary" text @click="volver()">Aceptar</v-btn>
            </v-card-actions>
          </v-card>

        </v-dialog>

      </v-row>
    </template>
    <CaruselImagenes :dialog.sync="dialogimagen" :id.sync="id" />
    <EliminarImagenes :dialog.sync="dialogimagenelimina" :id.sync="id" />
    <ordenar-imagenes :dialog.sync="dialogimagenordenar" :id.sync="id" />
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Upload from '../components/Upload'
import CaruselImagenes from '../components/CaruselImagenes'
import EliminarImagenes from '../components/EliminarImagenes'
import { db } from '../firebase'
import router from '../router'
import { storage } from '../firebase'
import OrdenarImagenes from '../components/OrdenarImagenes.vue'
export default {
  props: ['valor'],
  components: {
    Upload,
    CaruselImagenes,
    EliminarImagenes,
    OrdenarImagenes
  },
  data() {
    return {
      id: this.$route.params.id,
      datosVehiculo: '',
      estado: '',
      marca: '',
      itemsEstado: ['Ok', 'Baja'],
      uploadDialog1: false,
      uploadDialog2: false,
      dialogimagen: false,
      dialogimagenelimina: false,
      loading: false,
      guardado: false,
      dialogimagenordenar: false
    }
  },
  async created() {
    await this.getVehiculo(this.id)
    await this.getEstado(this.vehiculo.estadoRef.id)
    await this.getMarca(this.vehiculo.marcaRef.id)

  },
  methods: {
    ...mapActions(['getVehiculo', 'editarVehiculo']),

    async getMarca(id) {
      await db.collection('marcas').doc(id).get()
        .then(res => {
          this.marca = res.data().nombre
        })
    },

    async getEstado(id) {
      await db.collection('estados').doc(id).get()
        .then(res => {
          this.estado = res.data().nombre
        })
    },
    async guardar() {
      this.loading = true
      let refMarca = await db.collection('marcas').where("nombre", "==", this.marca).get()
      let refEstado = await db.collection('estados').where("nombre", "==", this.estado).get()
      let datos = {
        id: this.vehiculo.id,
        estadoRef: refEstado.docs[0].ref,
        marcaRef: refMarca.docs[0].ref,
        modelo: this.vehiculo.modelo,
        anio: this.vehiculo.anio,
        km: this.vehiculo.km,
        patente: this.vehiculo.patente,
        puertas: this.vehiculo.puertas,
        transmision: this.vehiculo.transmision,
        color: this.vehiculo.color,
        combustible: this.vehiculo.combustible,
        motor: this.vehiculo.motor,
        precio: this.vehiculo.precio,
        ubicacion: this.vehiculo.ubicacion,
        descripcion: this.vehiculo.descripcion,
        empresa: this.vehiculo.empresa
      }
      await this.editarVehiculo(datos)

      let empresa = this.vehiculo.empresa
      if(empresa == ''){
        empresa = null
      }

      let data = {
        anio: this.vehiculo.anio,
        color: this.vehiculo.color,
        combustible: this.vehiculo.combustible,
        estado: this.estado,
        id: this.vehiculo.id,
        km: this.vehiculo.km,
        marca: this.marca,
        modelo: this.vehiculo.modelo,
        motor: this.vehiculo.motor,
        patente: this.vehiculo.patente,
        precio: this.vehiculo.precio,
        puertas: this.vehiculo.puertas,
        transmision: this.vehiculo.transmision,
        ubicacion: this.vehiculo.ubicacion,
        descripcion: this.vehiculo.descripcion,
        empresa: empresa
      }
      let lista = JSON.parse(localStorage.getItem('filas'))
      lista = lista.map(item => {
        if (item.id === data.id) {
          return data
        }
        else {
          return item
        }
      })
      localStorage.setItem('filas', JSON.stringify(lista))
      this.loading = false
      this.guardado = true
    },
    cancelar() {
      router.push('/vehiculo')
    },
    volver() {
      this.guardado = false
      router.push('/vehiculo')
    },
  },
  computed: {
    ...mapState(['vehiculo']),
    inavilitar() {
      return this.valor === false ? 'disabled' : ''
    }
  },

}
</script>

<style scoped>
.titulo {
  color: #1867c0;
  text-align: center;
}

.disabled {
  pointer-events: none;
}
</style>