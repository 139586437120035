<template>
  <div>
    <v-container fluid v-if="(this.vehiculos.length === 0 && this.vacio === 1)">
      <v-row align="center" justify="center" style="height: 600px;">
        <Progreso />
      </v-row>
    </v-container>
    <div class="mt-4 ml-4 mr-4 mb-6">
      <h1 class="titulo">VEHICULOS</h1>
      <h2>
        <v-icon color="black">fas fa-filter</v-icon>
        Filtro
        <v-btn color="" text @click="limpiarFiltro()" style="margin-bottom:5px">
          <v-icon color="orange">fas fa-broom</v-icon>
        </v-btn>
      </h2>
      <v-card>
        <v-form>
          <v-container>
            <v-row>
              <v-col cols="12" md="4">
                <v-select v-model="selectMarca" :items="itemsMarca" label="Marca" @click="getMarcas">
                </v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model="filtro.modelo" type="text" label="Modelo">
                </v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model="filtro.anio" type="number" label="Año">
                </v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-select v-model="selectEstado" :items="itemsEstado" label="Estado">
                </v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model="filtro.patente" label="Patente">
                </v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-btn title="Buscar" block color="primary" :loading="loading" @click="filtradoAux(filtro)"
                  style="margin-top: 14px;">
                  <v-icon color="white" class="ml-2">fas fa-search</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
    </div>
    <div>

      <template>
        <v-data-table :headers="headers" :items="filas" class="elevation-1" style="margin: 17px ;"
          :item-class="itemRowBackground">
          <template v-slot:[`item.accion`]="{ item }">
            <v-btn small color="error" class="mr-2" v-if="item.estado === 'Ok'" @click="GuardarDatos(item)"
              title="Baja de vehículo">
              <v-icon small>
                fas fa-times-circle
              </v-icon>
            </v-btn>
            <router-link :to="{ name: 'Ver', params: { id: item.id } }">
              <v-btn small class="mr-2" title="Ver vehículo">
                <v-icon small color="black">
                  fas fa-eye
                </v-icon>
              </v-btn>
            </router-link>
            <router-link :to="{ name: 'Editar', params: { id: item.id } }">
              <v-btn small color="warning" class="mr-2 " title="Editar vehículo">
                <v-icon small>
                  fas fa-edit
                </v-icon>
              </v-btn>
            </router-link>

          </template>
        </v-data-table>
        <template>
          <v-row justify="center">
            <v-dialog v-model="dialog1" persistent max-width="370">
              <v-card>
                <v-card-title class="text-center"
                  style="font-size: 15px !important; color:#616067d1 !important ;font-weight: 400;justify-content:center">¿Seguro
                  que desea dar de baja este vehiculo?</v-card-title>
                <v-card-actions style="justify-content: center;">

                  <v-btn color="primary" text @click="aceptar(true)">Si</v-btn>
                  <v-btn color="primary" text @click="aceptar(false)">No</v-btn>
                </v-card-actions>
              </v-card>

            </v-dialog>

          </v-row>
        </template>
        <template>
          <v-row justify="center">
            <v-dialog v-model="dialog2" persistent max-width="370">
              <v-card>
                <v-card-title class="text-center"
                  style="font-size: 15px !important; color:#616067d1 !important ;font-weight: 400;justify-content:center">Se
                  dio de forma existosa la baja del vehiculo !!</v-card-title>
                <v-card-actions style="justify-content: center;">

                  <v-btn text color="primary" @click="dialog2 = false">Ok</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </template>
        <template>
          <v-row justify="center">
            <v-dialog v-model="loader" hide-overlay persistent width="300">
              <v-card color="primary" dark>
                <v-card-text>
                  Subiendo Imagenes ,por favor espere...
                  <v-progress-linear indeterminate color="white" class="mb-0">
                  </v-progress-linear>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-row>
        </template>
        <ErrorBusqueda :error="errorbusqueda" />
        <v-container>
          <v-row align="center" justify="center" style="margin-top: 8px;">
            <Exportar :valor="filas" />
            <Importar @accion="ActualizaDesserts" />
          </v-row>

        </v-container>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Exportar from '../components/Exportar.vue'
import Importar from '../components/Importar.vue'
import Progreso from '../components/Progreso'
import ErrorBusqueda from '../components/ErrorBusqueda'
import { db, storage } from '../firebase'

export default {
  components: {
    Progreso,
    Importar,
    Exportar,
    ErrorBusqueda,
  },
  data() {
    return {
      filtro: {
        marca: {},
        modelo: '',
        estado: '',
        anio: '',
        patente: ''
      },
      vehiculo: '',
      selectMarca: '',
      selectEstado: '',
      itemsMarca: [],
      itemsEstado: [],
      headers: [
        { text: 'Año', value: 'anio' },
        { text: 'Marca', value: 'marca' },
        { text: 'Modelo', value: 'modelo', sortable: false },
        { text: 'Empresa', value: 'empresa' },

        { text: 'Km', value: 'km', sortable: false },
        { text: 'Estado', value: 'estado' },
        { text: 'Patente', value: 'patente', sortable: false },
        { text: 'Accion', value: 'accion', sortable: false }
      ],
      desserts: [],
      filas: [],
      marca: '',
      marcas: [],
      estado: '',
      estados: [],
      loading: false,
      dialog1: false,
      dialog2: false,
      errorbusqueda: false,
      vacio: 0,
      loader: false,
      idsEliminar: []
    }
  },
  methods: {
    ...mapActions(['getVehiculos', 'aplicarFiltroVehiculo', 'darBajaVehiculo']),
    async filtradoAux(filtro) {


      // filtro:{
      //   marca:{},
      //   modelo:'',
      //   estado:'',
      //   anio:'',
      //   patente :''
      // }

      try {
        this.loading = true
        await this.getVehiculos()
        await this.comparaMarca(this.selectMarca, this.marcas)
        await this.comparaEstado(this.selectEstado, this.estados)
        this.aplicarFiltroVehiculo(filtro)
        this.desserts = []
        this.vehiculosFiltrado.forEach(async vehiculo => {
          await this.getMarca(vehiculo.marcaRef.id)
          let aux1 = this.marca
          await this.getEstado(vehiculo.estadoRef.id)
          let aux2 = this.estado
          let empresa = ''
          if (vehiculo.empresa) {
            empresa = vehiculo.empresa
          }
          let datos = {
            id: vehiculo.id,
            estado: aux2,
            marca: aux1,
            modelo: vehiculo.modelo,
            anio: vehiculo.anio,
            km: vehiculo.km,
            patente: vehiculo.patente,
            puertas: vehiculo.puertas,
            transmision: vehiculo.transmision,
            color: vehiculo.color,
            combustible: vehiculo.combustible,
            motor: vehiculo.motor,
            precio: vehiculo.precio,
            ubicacion: vehiculo.ubicacion,
            empresa: empresa
          }
          this.desserts.push(datos)
          this.loading = false
          if (this.vehiculosFiltrado.length === this.desserts.length) {
            this.filas = this.desserts
            let aux = {
              marca: this.filtro.marca,
              modelo: this.filtro.modelo,
              anio: this.filtro.anio,
              estado: this.filtro.estado,
              patente: this.filtro.patente
            }
            localStorage.setItem('filas', JSON.stringify(this.filas))
            localStorage.setItem('filtro', JSON.stringify(aux))
            this.cargarLocalStorage(this.filas, aux)
          }
        });
        if (this.vehiculosFiltrado.length == 0) {
          this.loading = false
          this.filas = []
          let aux = {
            marca: this.filtro.marca,
            modelo: this.filtro.modelo,
            anio: this.filtro.anio,
            estado: this.filtro.estado,
            patente: this.filtro.patente
          }
          localStorage.setItem('filas', JSON.stringify(this.filas))
          localStorage.setItem('filtro', JSON.stringify(aux))
          this.cargarLocalStorage(this.filas, aux)
        }

      } catch (error) {
        this.errorbusqueda = true
      }

    },
    getMarcas() {
      db.collection('marcas').get()
        .then(res => {
          res.forEach(doc => {
            let itemMarca = doc.data()
            itemMarca.id = doc.id
            this.marcas.push(itemMarca)
            this.itemsMarca.push(doc.data().nombre)
          })
        })
    },
    getEstados() {
      db.collection('estados').get()
        .then(res => {
          res.forEach(doc => {
            let itemEstado = doc.data()
            itemEstado.id = doc.id
            this.estados.push(itemEstado)
            this.itemsEstado.push(doc.data().nombre)
          })
        })
    },
    async getMarca(id) {
      await db.collection('marcas').doc(id).get()
        .then(res => {
          this.marca = res.data().nombre
        })
    },

    async getEstado(id) {
      await db.collection('estados').doc(id).get()
        .then(res => {
          this.estado = res.data().nombre
        })
    },
    comparaMarca(marca, marcas) {
      if (marca === '') {
        this.filtro.marca = ''
      }
      else {
        marcas.forEach(item => {
          if (item.nombre === marca) {
            this.filtro.marca = item
          }
        })

      }
    },
    comparaEstado(estado, estados) {
      if (estado === '') {
        this.filtro.estado = ''
      }
      else {
        estados.forEach(item => {
          if (item.nombre === estado) {
            this.filtro.estado = item
          }
        })
      }
    },

    ActualizaDesserts() {
      this.desserts = []
      if (this.vehiculosFiltrado.length !== 0) {
        this.loading = true
        this.vehiculosFiltrado.forEach(async vehiculo => {
          await this.getMarca(vehiculo.marcaRef.id)
          let aux1 = this.marca
          await this.getEstado(vehiculo.estadoRef.id)
          let aux2 = this.estado
          let datos = {
            id: vehiculo.id,
            estado: aux2,
            marca: aux1,
            modelo: vehiculo.modelo,
            anio: vehiculo.anio,
            km: vehiculo.km,
            patente: vehiculo.patente,
            puertas: vehiculo.puertas,
            transmision: vehiculo.transmision,
            color: vehiculo.color,
            combustible: vehiculo.combustible,
            motor: vehiculo.motor,
            precio: vehiculo.precio,
            ubicacion: vehiculo.ubicacion
          }
          this.desserts.push(datos)
          this.loading = false
          if (this.vehiculosFiltrado.length === this.desserts.length) {
            this.filas = this.desserts
            localStorage.setItem('filas', JSON.stringify(this.filas))
          }
        });
        //this.filas = this.desserts
      }
    },

    async DarBaja(vehiculo) {
      let refBaja = db.collection('estados').doc('2')
      let refMarca = await db.collection('marcas').where("nombre", "==", vehiculo.marca).get()
      let datos = {
        id: vehiculo.id,
        estadoRef: refBaja,
        marcaRef: refMarca.docs[0].ref,
        modelo: vehiculo.modelo,
        anio: vehiculo.anio,
        km: vehiculo.km,
        patente: vehiculo.patente,
        puertas: vehiculo.puertas,
        transmision: vehiculo.transmision,
        color: vehiculo.color,
        combustible: vehiculo.combustible,
        motor: vehiculo.motor,
        precio: vehiculo.precio,
        ubicacion: vehiculo.ubicacion
      }
      await this.darBajaVehiculo(datos)
      this.loader = false
      this.dialog2 = true
      this.vehiculo = ''
      this.ActualizaDesserts()
    },

    aceptar(valor) {
      this.dialog1 = false
      if (valor) {
        this.loader = true
        this.DarBaja(this.vehiculo)
      }
    },
    GuardarDatos(item) {
      this.vehiculo = item
      this.dialog1 = true
    },

    async cargarLocalStorage(filas, aux) {
      if (localStorage.getItem('filas')) {
        this.filas = JSON.parse(localStorage.getItem('filas'))
      }
      else {
        localStorage.setItem('filas', JSON.stringify(filas))
      }
      if (localStorage.getItem('filtro')) {
        let aux = JSON.parse(localStorage.getItem('filtro'))
        this.filtro = aux
        this.selectMarca = aux.marca.nombre
        this.selectEstado = aux.estado.nombre
      }
      else {
        localStorage.setItem('filtro', JSON.stringify(aux))
      }
    },



    itemRowBackground(item) {
      return this.filas.indexOf(item) % 2 == 0 ? '' : 'style-2'

    },

    vacio_vehiculos() {
      if (this.vehiculos.length > 0) {
        this.vacio = 1
      }
    },

    limpiarFiltro() {
      this.filtro.marca = ''
      this.filtro.estado = ''
      this.filtro.modelo = ''
      this.filtro.anio = ''
      this.filtro.patente = ''
      this.selectMarca = ''
      this.selectEstado = ''
      localStorage.setItem('filtro', JSON.stringify({
        marca: this.filtro.marca,
        modelo: this.filtro.modelo,
        anio: this.filtro.anio,
        estado: this.filtro.estado,
        patente: this.filtro.patente
      }))
    },

    async getVehiculosBaja() {
      storage.ref().child('/06LDIB0LzZnWXTbGg2uU').delete()
    },



  },
  computed: {
    ...mapState(['vehiculos', 'vehiculosFiltrado'])
  },
  created() {
    //this.getVehiculos()
    this.getVehiculosBaja()
    this.getMarcas()
    this.getEstados()
    this.cargarLocalStorage()
    this.vacio_vehiculos()
  },

}
</script>

<style>
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #a4bffd;
}

.style-2 {
  background-color: rgb(219, 219, 233);
}

.titulo {
  color: #1867c0;
  text-align: center;
  font-size: 40px;
}
</style>