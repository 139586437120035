<template>
<div>
    <input type="file" ref="boton" class="d-none" @change="buscarArchivo($event)">
    <v-btn 
        class="secondary mt-5 mb-5"  
        @click="$refs.boton.click()"
         :loading="loading"
    >
    Importar
    <v-icon small class="ml-2">fas fa-file-upload</v-icon>
    </v-btn>
    <v-dialog
        v-model="dialog"
        max-width="290"
    >
      <v-card>
            <v-card-title class="headline">Mensaje</v-card-title>

            <v-card-text class="text-center">
                Se Importo con exito el archivo.
            </v-card-text>

        <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                        color="primary"
                        text
                        @click="dialog = false"
                >
                    OK
                </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="dialog_invalid"
        max-width="350"
    >
      <v-card>
            <v-card-title class="headline">Mensaje</v-card-title>

            <v-card-text class="text-center">
                El formato del archivo es invalido. El formato del archivo debe ser .xls , .odp o .xlsx
            </v-card-text>

        <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                        color="primary"
                        text
                        @click="dialog_invalid = false"
                >
                    OK
                </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</div>
</template>

<script>
import  {db}  from '../firebase'
import { mapActions } from 'vuex'
export default {
    data() {
        return {
            loading :false,
            file:null,
            error:'',
            dialog:false,
            refBaja:'',
            refAlta:'',
            marcas:[],
            dialog_invalid : false
        }
    },
    methods: {
        ...mapActions(['darBajaVehiculo','darAltaVehiculo','getVehiculos']),
         buscarArchivo(event){
            try {
                this.loading = true
                this.file = event.target.files[0]
                if(this.file.type === "application/vnd.ms-excel" || this.file.type ==="application/vnd.oasis.opendocument.spreadsheet" || 
                    this.file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
                        var XLSX = require("xlsx")
                        let reader = new FileReader();
                        reader.onload = async function(e) {
                            let data = new Uint8Array(e.target.result)
                            let workbook = XLSX.read(data , {type: "array"} )
                            let worksheet = workbook.Sheets[workbook.SheetNames[0]]
                            let sheet = XLSX.utils.sheet_to_json(worksheet , { header : 1})
                            sheet = await this.limpiarSheets(sheet)
                            await  this.BajaVehiculos(sheet)
                            await this.AltaVehiculo(sheet)
                            await  this.ProcesarFilas(sheet)
                            await this.getVehiculos()
                            this.loading = false
                            this.dialog = true
                            this.$emit('accion')
                            this.getMarcas()
                        }.bind(this)
                        reader.readAsArrayBuffer(this.file)    
                }
                else{
                    this.loading = false
                    this.dialog_invalid = true
                }
            } catch (error) {
            }
        },

        async limpiarSheets(data){
            let lista = data.slice()
            let i = 0
            while (i < lista.length){
                if(lista[i].length === 0){
                    lista.splice(i,1)
                }
                else{
                    i++
                }
            }
            return lista
        },

        async ProcesarFilas(data){
            await this.CargarMarcas(data)
            data.forEach(vehiculo => {
                if(vehiculo[12] == undefined && vehiculo[12] != 'RADA'){
                    vehiculo.push("")
                }
            })
            await db.collection('vehiculos').get()
            .then(async res => {
                if(res.empty === true){
                    let cabecera = 0
                    
                    data.forEach(async vehiculo => {
                        if(cabecera === 0){
                            cabecera = 1
                        }
                        else{     
                            await this.CargarVehiculo(vehiculo)
                        }
                    })
                }
                else{
                    var c = 0 // c = 0 es la cabecera del archivo
                    data.forEach(async vehiculo => {
                        let b = 0 
                        res.forEach(async doc => {
                            if(doc.data().patente.toUpperCase() === vehiculo[5].toUpperCase()){
                                b = 1 //sirve para ver si el vheiculo esta en la base de datos
                                
                                await this.Actualizarvehiculo(vehiculo, doc.id)//Actualiza los datos del vehiculo
                            }
                        })
                        if(b === 0 && c !== 0){
                            await this.CargarVehiculo(vehiculo)
                        }
                        c++
                    })
                }
            })
        },
        async Actualizarvehiculo(vehiculo ,id){
            let marcaReferencia  = ''
            
            await db.collection('marcas').where('nombre' , 'in' , [vehiculo[1].toUpperCase().toString()]).get()
            .then(async  res =>{
                if(res.empty === true){
                    await db.collection('marcas').add({
                        nombre: vehiculo[1].toUpperCase()
                    })
                    .then(ref =>{
                        marcaReferencia = ref
                    })
                    .catch( error => {
                    })
                }
                else{
                    marcaReferencia = res.docs[0].ref
                }
            })
            await db.collection('vehiculos').doc(id).update({
                anio : vehiculo[0],
                marcaRef : marcaReferencia,
                modelo : vehiculo[2].toUpperCase(),
                km : vehiculo[3],
                ubicacion : vehiculo[4],
                patente : vehiculo[5].toUpperCase(),
                color :  vehiculo[6].toUpperCase(),
                transmision : vehiculo[7].toUpperCase(),
                motor : vehiculo[8].toUpperCase(),
                combustible : vehiculo[9],
                puertas : vehiculo[10],
                precio: vehiculo[11],
                estadoRef : this.refAlta,
                empresa: vehiculo[12]
            })
            .then(()=>{
            })
        },

        async CargarVehiculo(vehiculo){
            let marcaReferencia = ''
            
            await  db.collection('marcas').where('nombre' , 'in' , [vehiculo[1].toUpperCase().toString()]).get()
            .then( res =>{
                marcaReferencia = res.docs[0].ref
            })

            await db.collection('vehiculos').add({
                anio : vehiculo[0],
                marcaRef : marcaReferencia,
                modelo : vehiculo[2].toUpperCase(),
                km : vehiculo[3],
                ubicacion : vehiculo[4],
                patente : vehiculo[5].toUpperCase(),
                color :  vehiculo[6].toUpperCase(),
                transmision : vehiculo[7].toUpperCase(),
                motor : vehiculo[8].toUpperCase(),
                combustible : vehiculo[9],
                puertas : vehiculo[10],
                precio: vehiculo[11],
                estadoRef : this.refAlta,
                descripcion:'',
                UrlImagenesGrandes:[],
                UrlImagenesMini:[],
                empresa: vehiculo[11]
            })
            .then( () =>{
            })
        },

        async CargarMarcas(vehiculos){
            let b = 0
            let aux = []
            vehiculos.forEach(vehiculo => {
                if(b == 0){
                    b = b + 1 // para saltar la cabecera
                }
                else{
                    if(this.marcas.length > 0){
                        var found1 = this.marcas.find(element => element === vehiculo[1].toUpperCase())
                        if(found1 === undefined){
                            this.marcas.push(vehiculo[1].toUpperCase())
                            aux.push(vehiculo[1].toUpperCase())
                        }
                    }
                    else{
                        var found2 = aux.find(element => element === vehiculo[1].toUpperCase())
                        if(found2 === undefined){
                            aux.push(vehiculo[1].toUpperCase())
                        }
                    }
                }
            })
            aux.forEach(async marca =>{
                await db.collection('marcas').add({
                    nombre: marca
                })
                .then(res => {
                })
            })
        },
        async BajaVehiculos(data){
            await db.collection('vehiculos').get()
            .then(res =>{
                if(res.empty === true){
                }
                else{
                    res.forEach( async doc =>{
                        let b = 0
                        data.forEach(  vehiculo => {
                            if (doc.data().patente.toUpperCase() === vehiculo[5].toUpperCase()){
                                b = 1
                            }
                        })
                        if( b === 0){
                            await this.darBajaVehiculo({ id: doc.id , estadoRef:this.refBaja})    
                        }
                    })
                }
            })
        },
        async AltaVehiculo(data){
            await db.collection('vehiculos').get()
            .then(res => {
                if (res.empty === true){
                }
                else{
                    res.forEach(doc => {
                        data.forEach(async vehiculo => {
                            if(doc.data().patente.toUpperCase() === vehiculo[5].toUpperCase()){
                                if(doc.data().estadoRef.id === '2'){
                                    let idVehiculo = doc.id
                                    await this.darAltaVehiculo({id: idVehiculo , estadoRef: this.refAlta})
                                }
                            }
                        })
                    })
                }
            })
        },

        referenciaBaja(){
            this.refBaja = db.collection('estados').doc('2')
        },
        referenciaAlta(){
            this.refAlta = db.collection('estados').doc('1')
        },
        getMarcas(){
            this.marcas = []
            db.collection('marcas').get()
            .then(res => {
                if(res.empty === true){
                    this.marcas = []
                }
                else{
                    res.forEach(doc => {
                        this.marcas.push(doc.data().nombre)
                    })
                }
            })
        },
       
    },
    created() {
        this.referenciaBaja()
        this.referenciaAlta()
        this.getMarcas()
    }, 
}
</script>

<style>

</style>