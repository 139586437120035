<template>
  <v-row justify="center" >   
    <v-dialog :value ="dialog"  fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Imagenes</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn  icon dark @click="closeDialog">
                 <v-icon>fas fa-times</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list three-line subheader>
          <v-list-item>
            <v-list-item-content class="centrar">
              <v-list-item-title align="center" class="subtitulo">Imagen Mini</v-list-item-title>
              <v-card
                elevation="3"
                max-width="320"
                max-height = "240"
                style="margin-top:20px; margin-bottom:20px"
                v-if="this.imagenesMiniRef.length === 0"
              >
                        <img height="250" src="../assets/sinImagenes2.webp"  alt="" >
              </v-card>
              <v-card
                elevation="3"
                max-width="320"
                max-height = "240"
                style="margin-top:20px; margin-bottom:20px"
                v-if="this.imagenesMiniRef.length > 0"
              >
                        <img height="250" :src="minis[0]"  alt="" >
              </v-card>
            <!-- <v-carousel hide-delimiters v-if="minis.length > 0"> 
                <template v-for="(item, index) in minis"> 
                  <v-carousel-item v-if="(index + 1) % columns === 1 || columns === 1" 
                                  :key="index"
                  >   
                    <v-row class="flex-nowrap margen-carusel" style="height:50%"> 
                      <template v-for="(n,i) in columns"> 
                        <template v-if="(+index + i) <minis.length"> 
                          <v-col :key="i">
                            <v-sheet v-if="(+index + i) < minis.length" 
                                    height="120%"
                                    color="white"
                            >
                              <v-row class="fill-height"
                                    align="center"
                                    justify="center"
                              >
                                <div class="display-2">
                                    <img :src="minis[+index + i ]"  alt="">
                                </div>
                              </v-row>
                            </v-sheet>
                          </v-col> 
                        </template> 
                      </template> 
                    </v-row> 
                  </v-carousel-item> 
                </template> 
            </v-carousel>   -->
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list three-line subheader>
          <v-list-item>
            <v-list-item-content class="centrar">
              <v-list-item-title align="center" class="subtitulo">Imagenes</v-list-item-title>
                    <v-carousel hide-delimiters class="mt-4 ancho" 
                            v-if="this.imagensGrandesRef.length === 0"
                    >
                        <v-carousel-item
                            src="../assets/sinImagenes2.webp"
                        >
                        </v-carousel-item>
                    </v-carousel>
                    <v-carousel cycle hide-delimiters class=" ancho"
                        v-if="this.imagensGrandesRef.length >0"
                        style="margin-top:100px">
                                <v-carousel-item
                                    v-for="(item,i) in imagensGrandesRef"
                                    :key="i"
                                    :src="item.src"
                                >
                                </v-carousel-item>
                    </v-carousel>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {storage} from '../firebase'
export default {
    props :{
        dialog: {
            type: Boolean,
            required: true
        },
        id:{
            type: String
        },
        multiple:{
          type:Boolean,
          default:true
        },
    },
    data() {
        return {
            imagensGrandesRef : [],
            imagenesMiniRef:[]
        }
    },

    created() {
        this.getImagenes()
        
    },

    computed: {
      columns() {
          if (this.$vuetify.breakpoint.xl) {
            return 4;
          }

          if (this.$vuetify.breakpoint.lg) {
            return 3;
          }

          if (this.$vuetify.breakpoint.md) {
            return 2;
          }

          return 1;
    },
    minis(){
      let minisimagen=[]
      for (let index = 0; index < this.imagenesMiniRef.length; index++) {
        minisimagen.push(String(this.imagenesMiniRef[index].urlDescarga))
       
      }
      return minisimagen
    }
    },

    methods: {
        closeDialog(){
            this.$emit("update:dialog", false) 
        },

         getImagenes(){
           this.imagensGrandesRef = []
           this.imagenesMiniRef = []
            let ImagenesGrandes = storage.ref().child(this.id).child('Imagenes Grandes').listAll()
            .then(res =>{
                if(res.items.length > 0){
                    res.items.forEach(async img => {
                    let urlDescarga = await img.getDownloadURL()
                    this.imagensGrandesRef.push({src:urlDescarga})
                })
                }
            })
            let ImagenesMini = storage.ref().child(this.id).child('Imagenes Mini').listAll()
            .then(res =>{
                if(res.items.length > 0){
                    res.items.forEach(async img => {
                    let urlDescarga = await img.getDownloadURL()
                    this.imagenesMiniRef.push({urlDescarga})
                })
                }
            })
        },
    },

    watch: {
        dialog:function() {
            if(this.dialog){
                this.getImagenes()
            }
        }
    },
}
</script>

<style>

.ancho{
    width: 800px  !important;
}

.centrar {
    justify-content: center !important;
}

.subtitulo{
    font-size: 2.25rem;
    line-height: 1.5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: bold;
    color: #2196F3;
}
.margen-carusel{
      margin-top: 100px;
    /* margin-bottom: 10px; */
    margin-left: 20px;
}

</style>