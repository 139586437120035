<template>
    <div>
    <v-dialog  @click:outside="closeDialog" :value = "dialog"  max-width="550px">
        <!-- 1. @drop : Para detectar cuando los elementos se colocan en el área de nuestra zona de colocación
        2. @dragover : Para detectar cuándo se arrastran los elementos sobre nuestra zona de colocación
        3. @dragenter : Para detectar cuándo los artículos ingresan a nuestra zona de entrega
        4. @dragleave : Para detectar cuándo los artículos salen de nuestra zona de entrega -->
        <v-card
            @drop.prevent="onDrop($event)"
            @dragover.prevent="dragover = true"
            @dragenter.prevent="dragover = true"
            @dragleave.prevent=" dragover = false"
            :class=" { 'blue lighten-2' : dragover}"
        >
            <v-card-text>
                <v-row class="d-flex flex-column" dense align="center" justify="center">
                    <v-icon :class="[ dragover ? 'mt-2' : 'mt-5']"  color="primary" size="60">fas fa-cloud-upload-alt</v-icon>
                    <p v-if="valor" class="text-center mt-3" style="margin-bottom:-10px">  
                        Suelta tus imagenes aquí o haz clic para seleccionarlos.La imagen mini no debe superar a 50KB 
                    </p>
                    
                    <p v-if="!valor" class="text-center mt-3" style="margin-bottom:-10px">
                        Suelta tus imagenes aquí o haz clic para seleccionarlos.Cada imagen no debe superar a 1MB 
                    </p>
                    <v-file-input                                     
                            :hide-input= true
                            class="selecionar"
                            @change="buscarArchivo($event)"
                    ></v-file-input>
                </v-row>
                <v-virtual-scroll
                    v-if="uploadFiles.length > 0"
                    :items="uploadFiles"
                    height="150"
                    item-height="50"
                >
                    <template v-slot:default="{ item }">
                        <v-list-item :key="item.name">
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{item.name}}
                                    <span class="ml-3 text--secondary">
                                        {{(item.size/1024).toFixed(2)}} KB
                                    </span>
                                </v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-action>
                                <v-btn @click.stop="removeFile(item.name)" icon>
                                    <v-icon >fas fa-times-circle</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                        <v-divider></v-divider>
                    </template>
                </v-virtual-scroll>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn  text @click.stop="submit" color="primary" > Subir Imagen
                    
                </v-btn>
                <v-btn @click="closeDialog" icon class="ml-2">
                    <v-icon id="close-button">fas fa-times</v-icon>
                </v-btn>
                
                
            </v-card-actions>
        </v-card>
    </v-dialog>
     <template>
            <v-row justify="center">
              <v-dialog
                  v-model="typeFile"
                  persistent max-width="360">
                  <v-card>
                      <v-card-title class="text-center" style="font-size: 15px !important; color:#616067d1 !important ;font-weight: 400;justify-content:center">Imagenes en formato invalido :</v-card-title>
                      <v-card-text>
                          <v-virtual-scroll
                                v-if="uploadFileserror.length > 0"
                                :items="uploadFileserror"
                                :height="heightFormato" 
                                item-height="50"
                         >
                                <template v-slot:default="{ item }">
                                    <v-list-item :key="item.name">
                                        <v-list-item-content>
                                            <v-list-item-title style="color:#d61236f5;">
                                                {{item.name}}
                                                <span class="ml-3 text--secondary" style="color:#d61236f5 !important;">
                                                    {{(item.size/1024).toFixed(2)}} KB
                                                </span>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                    </v-virtual-scroll>
                      </v-card-text>
                      <v-card-actions style="justify-content: center;">
                              <v-btn color="primary" text @click="typeFile = false" >Aceptar</v-btn>
                      </v-card-actions>
                  </v-card>

              </v-dialog>
              
            </v-row>
        </template>
        <template>
            <v-row justify="center">
              <v-dialog
                  v-model="tamaño"
                  persistent max-width="350">
                  <v-card>
                      <v-card-title   class="text-center" style="font-size: 15px !important; color:#616067d1 !important ;font-weight: 400;justify-content:center">Hay imagenes que superan tamaño maximo </v-card-title>
                      <v-card-text>
                      </v-card-text>
                      <v-card-actions style="justify-content: center;">
                              <v-btn color="primary" text @click="tamaño = false" style="margin-top:-30px">Aceptar</v-btn>
                      </v-card-actions>
                  </v-card>

              </v-dialog>
              
            </v-row>
        </template>
        <template>
            <v-row justify="center">
              <v-dialog
                  v-model="vacio"
                  persistent max-width="360">
                  <v-card>
                      <v-card-title class="text-center" style="font-size: 15px !important; color:#616067d1 !important ;font-weight: 400;justify-content:center">No hay archivos para cargar</v-card-title>
                      <v-card-actions style="justify-content: center;">
                              <v-btn color="primary" text @click="vacio = false" >Aceptar</v-btn>
                      </v-card-actions>
                  </v-card>

              </v-dialog>
              
            </v-row>
        </template>
        <v-dialog
            v-model="loader"
            hide-overlay
            persistent
            width="300"
        >
            <v-card
                color="primary"
                dark
            >
                <v-card-text>
                    Subiendo Imagenes ,por favor espere...
                    <v-progress-linear
                        indeterminate
                        color="white"
                        class="mb-0"
                    >
                    </v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
        <template>
            <v-row justify="center">
              <v-dialog
                  v-model="exito"
                  persistent max-width="464">
                  <v-card>
                      <v-card-title class="text-center" style="font-size: 15px !important; color:#616067d1 !important ;font-weight: 400;justify-content:center">Se subieron exitosamente las imagenes !!</v-card-title>
                      <v-card-actions style="justify-content: center;">
                              <v-btn color="primary" text @click="exito = false" >Aceptar</v-btn>
                      </v-card-actions>
                  </v-card>

              </v-dialog>
              
            </v-row>
            <v-row justify="center">
              <v-dialog
                  v-model="una"
                  persistent max-width="464">
                  <v-card>
                      <v-card-title class="text-center" style="font-size: 15px !important; color:#616067d1 !important ;font-weight: 400;justify-content:center">Por favor , arrastre o selecione una imagen Mini por vehiculo</v-card-title>
                      <v-card-actions style="justify-content: center;">
                              <v-btn color="primary" text @click="una = false" >Aceptar</v-btn>
                      </v-card-actions>
                  </v-card>

              </v-dialog>
              
            </v-row>
        </template>
        <template>
            <v-row justify="center">
              <v-dialog
                  v-model="error"
                  persistent max-width="500">
                  <v-card>
                      <v-card-title  class="text-center" style="font-size: 15px !important; color:#616067d1 !important ;font-weight: 400;justify-content:center">Ah ocurrido un problema , no se subieron las imagenes intente nuevamente</v-card-title>
                      <v-card-actions style="justify-content: center;">
                              <v-btn color="primary" text @click="error = false" >Aceptar</v-btn>
                      </v-card-actions>
                  </v-card>

              </v-dialog>
              
            </v-row>

            <v-row justify="center">
              <v-dialog
                  v-model="remplazar"
                  persistent max-width="504">
                  <v-card>
                      <v-card-title class="text-center" style="font-size: 15px !important; color:#616067d1 !important ;font-weight: 400;justify-content:center">Ya existe una imagen mini para este vehiculo , desea remplazarla ?</v-card-title>
                      <v-card-actions style="justify-content: center;">
                              <v-btn color="primary" text @click="remplazarImagen()" >Si</v-btn>
                              <v-btn color="primary" text @click="remplazar = false" >No</v-btn>
                      </v-card-actions>
                  </v-card>

              </v-dialog>
              
            </v-row>
        </template>
    </div>
</template>

<script>
import  {db ,storage }  from '../firebase'
export default {
    name : "Upload",
    props : {
        dialog: {
            type: Boolean,
            required: true
        },
        multiple : {
            type:Boolean,
            default : true
        },
        id:{
            type: String
        },
        mini:{
            type:Boolean
        }
    },
    data() {
        return {
            dragover : false,
            uploadFiles : [],
            uploadFileserror : [],
            uploadFileTamaño: [],
            typeFile : false,
            tamaño : false,
            vacio :false,
            file:'',
            urlTemporal:[],
            loader:false,
            urlDescargaMini:[],
            urlDescargaGrandes:[],
            exito:false,
            error:false,
            valor : this.mini,
            una:false,
            imagenesMini:'',
            remplazar:false,
            imagenRepmlazar:''
        }
    },
    methods: {
        closeDialog(){
            this.$emit("update:dialog", false)
            this.uploadFiles = [];
            this.uploadFileserror = [];
            this.uploadFileTamaño = []; 
        },

        removeFile(fileName){
            const index = this.uploadFiles.findIndex(
                file => file.name === fileName
            )
            // Si el archivo está en archivos cargados, elimínelo
           if (index > -1){
               this.uploadFiles.splice(index , 1)
           } 
        },

        onDrop(e) {
            this.dragover = false
            if(this.mini){
                let j = 0
                while ( j < e.dataTransfer.files.length) {
                    j++    
                }
                if(j + this.uploadFiles.length>1){
                    this.una = true
                }
                else{
                    if (e.dataTransfer.files[0].type === 'image/webp' || e.dataTransfer.files[0].type === 'image/jpeg' || e.dataTransfer.files[0].type ==='image/tiff'){
                        if(e.dataTransfer.files[0].size < 51200){
                            this.uploadFiles.push(e.dataTransfer.files[0])
                        }
                        else{
                            this.uploadFileTamaño.push(e.dataTransfer.files[0])
                        }
                    }
                    else{
                        this.uploadFileserror.push(e.dataTransfer.files[0])
                    }
                } 
            }
            else{
                for (let i = 0; i < e.dataTransfer.files.length; i++) {
                    if (e.dataTransfer.files[i].type === 'image/webp' || e.dataTransfer.files[i].type === 'image/jpeg' || e.dataTransfer.files[i].type ==='image/tiff'){
                        // if(!this.mini){
                            if(e.dataTransfer.files[i].size < 1048576){
                                this.uploadFiles.push(e.dataTransfer.files[i])
                            }
                            else{
                                this.uploadFileTamaño.push(e.dataTransfer.files[i])
                            }
                        // }
                        // else{
                        //     // let j = 0
                        //     // while ( j < e.dataTransfer.files.length) {
                        //     //     j++    
                        //     // }
                        //     // if(j>1){
                        //     // }
                        //     // else{
                        //     //     if(e.dataTransfer.files[i].size < 51200){
                        //     //         this.uploadFiles.push(e.dataTransfer.files[i])
                        //     //     }
                        //     //     else{
                        //     //         this.uploadFileTamaño.push(e.dataTransfer.files[i])
                        //     //     }
                        //     // } 
                        // }
                    }
                    else{
                        this.uploadFileserror.push(e.dataTransfer.files[i])
                    }
                    
                }
                
            }
            if( this.uploadFileserror.length > 0){
                this.typeFile = true
            }
            if( this.uploadFileTamaño.length > 0){
                this.tamaño = true
            }
            
        },

        async remplazarImagen(){
            this.remplazar = false
            this.loader = true
            var desertRef =  await storage.ref().child(this.id).child('Imagenes Mini/'+this.imagenesMini.toString()).delete()
            const refImagen =  storage.ref().child(this.id).child('Imagenes Mini').child(this.imagenRepmlazar.name)
            const res = await refImagen.put(this.imagenRepmlazar)
            const urlDownload = await refImagen.getDownloadURL()
            this.urlDescargaMini.push(urlDownload)
            await this.GuardarImagenMinis(this.id,this.urlDescargaMini)
            this.getImagenMini()
            this.urlDescargaMini=[]
            this.loader = false
            this.exito = true
        },

        async subirImagen(element){
            try {
                if(this.mini){
                    if(this.imagenesMini.length != 0 ){
                        this.remplazar = true
                        this.imagenRepmlazar = element
                    }
                    else{
                        const refImagen =  storage.ref().child(this.id).child('Imagenes Mini').child(element.name)
                        const res = await refImagen.put(element)
                        const urlDownload = await refImagen.getDownloadURL()
                        this.urlDescargaMini.push(urlDownload)
                    }
                }
                else{
                    const refImagen =  storage.ref().child(this.id).child('Imagenes Grandes').child(element.name)
                    const res = await refImagen.put(element)
                    const urlDownload = await refImagen.getDownloadURL()
                    this.urlDescargaGrandes.push(urlDownload)
                }
                    
            } catch (error) {
                this.error = true
            }
            finally{
                this.loader = false
                this.closeDialog()
                if(!this.error){
                    if(this.remplazar == false){
                        this.exito = true
                    }
                }
                
            }
        },
       async GuardarImagenMinis(id,listaMini){
            await db.collection('vehiculos').doc(id).update({
                UrlImagenesMini : listaMini
            })
            .then(() => {
            })
        },

       async GuardarImagenGrande(id,listaGrande){
           await db.collection('vehiculos').doc(id).update({
                UrlImagenesGrandes : listaGrande
            })
            .then(() => {
            })
        },


        getImagenMini(){
            let ImagenesMini = storage.ref().child(this.id).child('Imagenes Mini').listAll()
            .then(res =>{
                if(res.items.length > 0){
                    this.imagenesMini = res.items[0].name
                //     res.items.forEach(async img => {
                //     let urlDescarga = await img.getDownloadURL()
                //     this.imagenesMini.push({urlDescarga})
                // })
                }
            })
        },

        subirImagenes(files){
            try {
                    files.forEach( async element => {
                        await this.subirImagen(element)
                        if(this.urlDescargaMini.length > 0 && this.remplazar == false){
                           await  this.GuardarImagenMinis(this.id,this.urlDescargaMini)
                        } 
                        if (this.urlDescargaGrandes.length>0){
                           await  this.GuardarImagenGrande(this.id,this.urlDescargaGrandes)
                        }
                    })
                } catch (error) {
                }
        },

         submit(){

            if (!this.uploadFiles.length > 0){
                this.vacio = true
            }
            else{
                this.loader = true 
                this.uploadFiles.forEach(element =>{
                    const reader = new FileReader();
                    reader.readAsDataURL(element);
                    reader.onload = (e) => {
                        this.urlTemporal.push(e.target.result) 
                    }
                })
                this.subirImagenes(this.uploadFiles)
                
            }
        },

        buscarArchivo(e){
            this.file = e
             if (this.file.type === 'image/webp' || this.file.type === 'image/jpeg' || this.file.type ==='image/tiff'){
                 if(!this.mini){
                     if(this.file.size < 1048576){
                         this.uploadFiles.push(this.file)
                     }
                     else{
                         this.uploadFileTamaño.push(this.file)
                     }
                 }
                 else{
                     if(this.uploadFiles.length == 1){
                         this.una = true
                     }
                     else{
                         if (this.uploadFiles.length < 1){
                             if(this.file.size < 51200){
                                 this.uploadFiles.push(this.file)
                             }
                             else{
                                 this.uploadFileTamaño.push(this.file)
                             }

                         }
                     }
                 }
            }
             else{
                    this.uploadFileserror.push(this.file)
            }
            if( this.uploadFileserror.length > 0){
                this.typeFile = true
            }
            if( this.uploadFileTamaño.length > 0){
                this.tamaño = true
            }
        }
    },
    computed: {
        heightFormato(){
            return this.uploadFileserror.length > 1 ? '150' : '50'
        },

        heightTamaño(){
            return this.uploadFileTamaño.length > 1 ? '150' : '50'
        }
    },
    created() {
        this.getImagenMini()
    },
}
</script>

<style scoped>

</style>