var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
    name: 'DetalleVehiculo',
    params: {
      vehiculo: _vm.datoVehiculo.patente,
      datos: _vm.datoVehiculo,
      lista_vehiculos: _vm.vehiculos,
    },
  }}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"ml-2 mr-3 mt-4 mb-4 theme--light zoom",class:hover ? 'borde' : '',attrs:{"width":"320","height":"450","elevation":hover ? 18 : 3}},[(
          _vm.datoVehiculo.imagenesMinis &&
          _vm.datoVehiculo.imagenesMinis.length !== 0
        )?_c('v-img',{attrs:{"src":_vm.datoVehiculo.imagenesMinis[0],"height":"200px"}},[_c('v-row',{attrs:{"justify":"start"}},[_c('div',{staticClass:"label label-primary"},[_vm._v(_vm._s(_vm.datoVehiculo.marca))])])],1):_vm._e(),(
          _vm.datoVehiculo.imagenesMinis &&
          _vm.datoVehiculo.imagenesMinis.length === 0
        )?_c('v-img',{attrs:{"src":require("../assets/sinImagenes2.webp"),"height":"200px"}}):_vm._e(),_c('v-row',{attrs:{"justify":"center","align":"center"}},[(
            _vm.datoVehiculo.anio &&
            _vm.datoVehiculo.marca &&
            _vm.datoVehiculo.modelo
          )?_c('v-card-title',{staticStyle:{"height":"50px"}},[_c('h2',{staticClass:"marca text-center"},[_vm._v(" "+_vm._s(_vm.datoVehiculo.anio)+" "+_vm._s(_vm.datoVehiculo.marca)+" "+_vm._s(_vm.datoVehiculo.modelo)+" ")])]):_vm._e()],1),_c('v-row',{staticStyle:{"margin-top":"-20px"},attrs:{"justify":"center","align":"center"}},[_c('v-card-subtitle',[_c('div',{staticClass:"descripcion"},[_vm._v(" "+_vm._s(_vm.datoVehiculo.transmision)+" | "+_vm._s(_vm.datoVehiculo.km)+" KM "+_vm._s(_vm.datoVehiculo.motor)+" ")])])],1),_c('v-row',{staticStyle:{"margin-top":"10px"},attrs:{"justify":"center","align":"center"}},[_c('v-card-title',{staticClass:"precio"},[_vm._v(" PRECIO DEL CONCESIONARIO ")]),_c('v-card-title',{staticClass:"precio",staticStyle:{"margin-top":"-30px"}},[_vm._v(" $"+_vm._s(Intl.NumberFormat().format(_vm.datoVehiculo.precio))+" ")])],1),_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-card-actions',[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
              name: 'DetalleVehiculo',
              params: {
                vehiculo: _vm.datoVehiculo.patente,
                datos: _vm.datoVehiculo,
                lista_vehiculos: _vm.vehiculos,
              },
            },"target":"_blank"}},[_c('v-btn',{attrs:{"color":"#1B75BA"}},[_c('span',{staticStyle:{"color":"snow"}},[_vm._v("Mas Informacion")])])],1)],1)],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }