<template>
  <div>
    <v-app>
      <Header />
      <v-main style="padding: 0px 0px  !important;">
        <section id="hero">
          <v-row no-gutters>
            <v-carousel cycle show-arrows-on-hover v-bind:height="$vuetify.breakpoint.width > 960 ? '530px' : 'auto'">
              <v-carousel-item v-for="(banner, i) in banners" :key="i">
                <v-img :src="banner.src" height="auto"></v-img>
              </v-carousel-item>
            </v-carousel>
          </v-row>
        </section>
        <section id="tarjetas-vehiculos" class="grey lighten-4">
          <v-container>
            <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center " style="color:black !important">
              Vehiculos</h2>
            <v-responsive class="mx-auto mb-12" width="56">
              <v-divider class="mb-1"></v-divider>

              <v-divider></v-divider>
            </v-responsive>
          </v-container>

          <!-- NOTAS -->
          <!-- Acá se renderiza todo el filtro -->
          <!-- :loader que recibe loader inicia en true y cuando se cumple la promesa de los vehiculos hace extension this.$emit("update:loader", false) -->

          <!-- ESTO ES LO QUE SE DEBE MANDAR CUANDO LLAMAMOS AL EVENTO CLICK EN "MAS INFORMACION" EN UN ROUTER-LINK
        :to="{ name: 'DetalleVehiculo', params: { vehiculo: datoVehiculo.patente, datos: datoVehiculo, lista_vehiculos: vehiculos } }" -->

          <!-- <Tarjetas :loader.sync="loader" :listaMarcas.sync="marcas" /> -->


          <Usados :loader.sync="loader" />
          <div class="py-12"></div>
        </section>

        <v-sheet id="contact" color="#1d1c1c" dark tag="section" tile>
          <div class="py-4"></div>
          <Contacto id="contacto" />
          <div class="py-4"></div>
        </v-sheet>
      </v-main>
      <Loader :loader="loader" />
      <Footer />
      <v-btn v-scroll="onScroll" v-show="fab" fab dark fixed bottom left color="#9c1313" @click="toTop">
        <v-icon>fas fa-arrow-circle-up</v-icon>
      </v-btn>
      <v-btn v-scroll="onScrollWhatsapp" v-show="fab2" fab dark fixed bottom right
        href="https://wa.me/5493875722779?text=Me%20interesa%20el%20auto%20que%20estás%20vendiendo" target="_blank">
        <v-img src="../assets/whatsapp.webp"></v-img>
      </v-btn>
    </v-app>
  </div>
</template>

<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import Contacto from '../components/Contacto.vue'
import Loader from '../components/Loader.vue'
import { mapActions, mapState } from 'vuex'
import Usados from '../components/Usados.vue'
export default {
  components: {
    Header,
    Footer,
    Contacto,
    Loader,
    Usados
  },

  data() {
    return {
      features: [
        {
          img: require('../assets/conocenos2.webp'),
          title: 'Visón',
          text: 'Ser líderes en todos los mercados que desarrollamos.',
        },
        {
          img: require('../assets/conocenos4.webp'),
          title: 'Misión',
          text: 'Poner al alcance de nuestros clientes la mejor y más alta tecnología a través de una constante mejora tendiente a la satisfacción total del cliente.',
        },
        {
          img: require('../assets/conocenos3.webp'),
          title: 'Valores',
          text: 'Transparencia y confianza. Seguridad. Honestidad. Orientación al cliente.',
        },
      ],
      stats: [
        ['fas fa-phone', '+54 387 4270700'],
        ['fab fa-whatsapp', '+54 387 4270700'],
        ['fas fa-envelope', 'dycar-usados@dycar.com'],
        ['fas fa-map-marker-alt', 'Salta (Av. Paraguay 2651)'],
        ['fab fa-facebook-square', 'Dycar Usados'],
        ['fab fa-instagram', 'Dycar Usados'],
      ],
      banners: [
        {
          src: require('../assets/Banner-principal-1.webp')
        },
        {
          src: require('../assets/Banner-principal-2.webp')
        },
        {
          src: require('../assets/Banner-principal-3.webp')
        },
        // {
        //   src:require('../assets/Banner-Dycar-Usados2.webp')
        // },
      ],
      titulo: 'Conocenos',
      texto: ' Somos un equipo profesional diverso e inspirado que, a partir del esfuerzo individual y del trabajo en equipo, busca la satisfacción total del cliente, brindándole una excelente atención antes, durante y después de la venta.\r\nEl respeto por la gente y la mejora continua de nuestros productos y servicios da como resultado la lealtad y la fidelidad de nuestros clientes con la marca, haciendo de DyCar una compañía líder y confiable del mercado automotriz',
      imagen: require('../assets/conocenos1.webp'),
      fab: false,
      loader: true,
      screen: screen.width,
      fab2: true
    }
  },

  computed: {
    ...mapState(['marcas']),
    tamañoimagen() {
      if (screen.width < 1024) {
        return '315'
      }
      else {
        return '540'
      }
    },
  },

  methods: {
    ...mapActions(['getMarcas']),
    onScroll(e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.fab = top > 20
    },
    onScrollWhatsapp(e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.fab2 = top >= 0
    },
    toTop() {
      this.$vuetify.goTo(0)
    }
  },

  async created() {
    await this.getMarcas()
  },
}
</script>

<style scoped></style>