<template>
  <div class="text-center">
    <v-dialog
      v-model="loader"
      hide-overlay
      persistent
      fullscreen
      light
      content-class="loading-dialog"
    >
      <v-container fill-height>
        <v-row
          align-content="center" 
          justify="center" 
          align-content-sm="center"
          align-content-md="center"
          align-content-lg="center"
          align-content-xl="center"
          justify-xl="center"
          justify-sm="center"
          justify-lg="center"
          justify-md="center"
        >
              <vue-loaders-line-scale color="red" scale="1" />
        </v-row>
      </v-container>
        
    </v-dialog>
  </div>
</template>

<script>
  export default {
    props: {
      loader: Boolean
    },
    data () {
      return {

      }
    },

    watch: {

    },
  }
</script>

<style>
  .loading-dialog{
    background-color: white
  }
</style>