<template>
  <div>
    <v-app-bar app color="white" height="70">

      <v-app-bar-nav-icon v-if="$vuetify.breakpoint.smAndDown" style="color:#808080"
        @click.stop="menu = !menu"></v-app-bar-nav-icon>

      <a v-if="$vuetify.breakpoint.mdAndUp" href="/home" style="text-decoration: none;" class="d-flex">
        <v-img height="40" width="165" class="mt-1" src="../assets/Logo-Dycar.webp" style="margin-right:15px"></v-img>
        <v-img src="../assets/eurodycar.webp"></v-img>
      </a>

      <a v-if="$vuetify.breakpoint.smAndDown" href="/home" style="text-decoration: none;">
        <v-img height="30" width="150" class="mt-1" src="../assets/dycar-logo.webp" style="margin-left:8px"></v-img>
        <v-img src="../assets/eurodycar.webp"></v-img>
      </a>

      <v-bottom-navigation v-if="$vuetify.breakpoint.mdAndUp" :value="value" color="#1B75BA"
        style="box-shadow:none !important;padding-left: 0px;padding-right: 0px;margin-right: 300px;">
        <v-btn @click="$vuetify.goTo('#tarjetas-vehiculos')">
          <router-link style="text-decoration: none!important;" :to="{ name: 'Home', hash: '#tarjetas-vehiculos' }">
            <span style="color:#808080;">VEHICULOS</span>
          </router-link>
        </v-btn>

        <!-- <v-btn @click="$vuetify.goTo('#nosotros')">
                  <span style="color:#808080">NOSOTROS</span>
                </v-btn> -->

        <v-btn @click="$vuetify.goTo('#contacto')">
          <router-link style="text-decoration: none!important;" :to="{ name: 'Home', hash: '#contacto' }">
            <span style="color:#808080">CONTACTO</span>
          </router-link>
        </v-btn>

      </v-bottom-navigation>
      <v-spacer></v-spacer>


      <a href="https://wa.me/5493875722779?text=Me%20interesa%20el%20auto%20que%20estás%20vendiendo" class="mr-2"
        style="text-decoration: none;" target="_blank">
        <v-icon size="36" color="#00e676">fab fa-whatsapp</v-icon>
      </a>
    </v-app-bar>
    <v-navigation-drawer v-model="menu" absolute temporary color="#ffffff"
      style="position:fixed; top:0; left:0; overflow-y:scroll;">
      <v-list nav dense>
        <v-list-item-group v-model="group" active-class="deep-light-blue--text text--accent-4" color="primary">
          <v-list-item @click="$vuetify.goTo('#tarjetas-vehiculos')">
            <v-list-item-icon><v-icon>fas fa-car</v-icon></v-list-item-icon>
            <v-list-item-title>VEHÍCULOS</v-list-item-title>
          </v-list-item>

          <!-- <v-list-item @click="$vuetify.goTo('#nosotros')">
              <v-list-item-icon><v-icon>mdi-account-group-outline</v-icon></v-list-item-icon>
              <v-list-item-title>NOSOTROS</v-list-item-title>
            </v-list-item> -->

          <v-list-item @click="$vuetify.goTo('#contacto')">

            <v-list-item-icon><v-icon>fas fa-envelope</v-icon></v-list-item-icon>
            <v-list-item-title>CONTACTO</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

  </div>
</template>

<script>
export default {
  data() {
    return {
      value: 1,
      screen: screen.width,
      menu: false,
      group: null,
    }
  },
  watch: {
    group: function () {
      this.menu = false
    }
  },
}
</script>

<style >
/* span.v-btn__content{
  color: black;
} */
</style>