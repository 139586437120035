<template>
  <v-progress-circular
      :size="100"
      color="primary"
      indeterminate
    ></v-progress-circular>
</template>

<script>
export default {

}
</script>

<style>

</style>