<template>
  <v-row justify="center" >   
    <v-dialog :value ="dialog"  fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Imagenes</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn  icon dark @click="closeDialog">
                 <v-icon>fas fa-times</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list three-line subheader>
          <v-list-item>
            <v-list-item-content class="centrar">
              <v-list-item-title align="center" class="subtitulo">Imagen Mini</v-list-item-title>
              <v-card
                elevation="3"
                max-width="320"
                max-height = "240"
                style="margin-top:20px; margin-bottom:20px"
                v-if="this.imagenesMiniRef.length === 0"
              >
                        <img height="250" src="../assets/sinImagenes2.webp"  alt="" >
              </v-card>
              <v-card
                flat
                max-width="265"
                max-height = "250"
                style="margin-top:20px; margin-bottom:20px"
                v-if="this.imagenesMiniRef.length > 0"
                
              >
                        <img height="200" :src="minis[0]"  alt="" >
                        <v-row align="center" justify="center">
                            <v-card-actions>
                                    <v-btn
                                        color="error"
                                        style="margin-top:10px"
                                        @click="eliminarmini"
                                    >
                                        <v-icon small>fas fa-trash-alt</v-icon>
                                    </v-btn>
                            </v-card-actions>

                        </v-row>
              </v-card>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list three-line subheader>
          <v-list-item>
            <v-list-item-content class="centrar">
              <v-list-item-title align="center" class="subtitulo">Imagenes</v-list-item-title>
                      <v-alert
                          dense
                          type="info"
                          :value="eliminado"
                          transition="scale-transition"
                        >
                          La Imagen a sido <strong>Eliminada</strong> .
                        </v-alert>
                    <v-carousel hide-delimiters class="mt-4 ancho" 
                            v-if="this.imagensGrandesRef.length === 0"
                    >
                        <v-carousel-item
                            src="../assets/sinImagenes2.webp"
                        >
                        </v-carousel-item>
                    </v-carousel>
                                <v-row v-if="this.imagensGrandesRef.length > 0" align="center" justify="center">
                                        <v-col v-for="(card,i) in imagensGrandesRef" :key="i" cols="12" md="3" sm="3" >
                                                    <v-card 
                                                        flat
                                                        max-width="265"
                                                        max-height = "250"
                                                        style="margin-top:20px; margin-bottom:20px"
                                                    >
                                                            <v-img
                                                                height="200"
                                                                :src="card.src"      
                                                                
                                                            ></v-img>
                                                            <v-row align="center" justify="center">
                                                                <v-card-actions>
                                                                        <v-btn
                                                                            color="error"
                                                                            style="margin-top:10px"
                                                                            @click="eliminargrande(i)"
                                                                        >
                                                                            <v-icon small>fas fa-trash-alt</v-icon>
                                                                        </v-btn>
                                                                </v-card-actions>

                                                            </v-row>
                                                    </v-card>
                                        </v-col>
                            </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
    
  </v-row>
</template>

<script>
import {storage , db} from '../firebase'
import Swal from 'sweetalert2'
export default {
    props :{
        dialog: {
            type: Boolean,
            required: true
        },
        id:{
            type: String
        },
        multiple:{
          type:Boolean,
          default:true
        },
    },
    data() {
        return {
            imagensGrandesRef : [],
            imagenesMiniRef:[],
            eliminado:false
        }
    },

    created() {
        this.getImagenes()
        
    },

    computed: {
      columns() {
          if (this.$vuetify.breakpoint.xl) {
            return 4;
          }

          if (this.$vuetify.breakpoint.lg) {
            return 3;
          }

          if (this.$vuetify.breakpoint.md) {
            return 2;
          }

          return 1;
    },
    minis(){
      let minisimagen=[]
      for (let index = 0; index < this.imagenesMiniRef.length; index++) {
        minisimagen.push(String(this.imagenesMiniRef[index].src))
       
      }
      return minisimagen
    }
    },

    methods: {
        closeDialog(){
            this.$emit("update:dialog", false) 
        },

         getImagenes(){
           this.imagensGrandesRef = []
           this.imagenesMiniRef = []
            let ImagenesGrandes = storage.ref().child(this.id).child('Imagenes Grandes').listAll()
            .then(res =>{
                if(res.items.length > 0){
                    res.items.forEach(async img => {
                    let urlDescarga = await img.getDownloadURL()
                    this.imagensGrandesRef.push({src:urlDescarga , name:img.name})
                })
                }
            })
            let ImagenesMini = storage.ref().child(this.id).child('Imagenes Mini').listAll()
            .then(res =>{
                if(res.items.length > 0){
                    res.items.forEach(async img => {
                    let urlDescarga = await img.getDownloadURL()
                    this.imagenesMiniRef.push({src:urlDescarga , name:img.name})
                })
                }
            })
        },

        

        async eliminargrande(indice){
          const Swal = require('sweetalert2')
            let name = this.imagensGrandesRef[indice].name
            let removed=[]
            for (let i = 0; i < this.imagensGrandesRef.length; i++) {
                if(name !=  this.imagensGrandesRef[i].name){
                    removed.push(this.imagensGrandesRef[i].src)
                }            
            }
            await db.collection('vehiculos').doc(this.id).update({
                UrlImagenesGrandes :removed
            })
            .then(() => {
            })
            await storage.ref().child(this.id).child('Imagenes Grandes').child(name).delete()
            .then(() => {
            })
            // this.eliminado=true
            await this.getImagenes()
            // this.syncDelay(5000)

            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Imagen Eliminada',
              showConfirmButton: false,
              timer: 1500
            })
            // const Toast = Swal.mixin({
            //   toast: true,
            //   position: 'top-end',
            //   showConfirmButton: false,
            //   timer: 3000,
            //   timerProgressBar: true,
            //   didOpen: (toast) => {
            //     toast.addEventListener('mouseenter', Swal.stopTimer)
            //     toast.addEventListener('mouseleave', Swal.resumeTimer)
            //   }
            // })

            // Toast.fire({
            //   icon: 'success',
            //   title: 'IMAGEN ELIMINADA',
            //   color:'#fff'
            // })
            
        },

        async eliminarmini(){
            let name = this.imagenesMiniRef[0].name
            await db.collection('vehiculos').doc(this.id).update({
                UrlImagenesMini :[]
            })
            .then(() => {
            })
            await storage.ref().child(this.id).child('Imagenes Mini').child(name).delete()
            .then(() => {
            })

            await this.getImagenes()
        },
    },

    watch: {
        dialog:function() {
            if(this.dialog){
                this.getImagenes()
            }
        },
    },
}
</script>

<style>

.ancho{
    width: 800px  !important;
}

.centrar {
    justify-content: center !important;
}

.subtitulo{
    font-size: 2.25rem;
    line-height: 1.5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: bold;
    color: #2196F3;
}
.margen-carusel{
      margin-top: 100px;
    /* margin-bottom: 10px; */
    margin-left: 20px;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

</style>