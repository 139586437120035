<template>
  <v-row dense>
    <v-col v-for="(vehiculo, index) in vehiculos" :key="index" xs="12" sm="12" xl="3" md="4" lg="4">
      <Vehiculo :vehiculo="vehiculo" :vehiculos="vehiculos" />
    </v-col>
  </v-row>
</template>

<script>
import Vehiculo from './Vehiculo.vue';

export default {
  props: {
    vehiculos: Array
  },

  components: { Vehiculo }
}
</script>
