<template>
        <v-row justify="space-around">
                <v-col cols="auto">
                    <v-dialog
                        transition="dialog-bottom-transition"
                        max-width="600"
                        v-model="error"
                    >
                            <v-card>
                                <v-toolbar
                                color="warning"
                                dark
                                align = "center"
                                
                                >
                                <v-icon class="mr-3">fas fa-exclamation-triangle</v-icon>
                                Ocurrio un Problema</v-toolbar>
                                <v-card-text>
                                <div class="text-h4 pa-4" align = "center" justify= "center">No se pudo recuperar  los datos para la busqueda solicitada</div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                <v-btn
                                    text
                                    @click="error = false"
                                >Cerrar</v-btn>
                                </v-card-actions>
                            </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
</template>

<script>
export default {
    props:[ 'error' ],
}
</script>

<style >
.v-toolbar__content{
    justify-content: center;
}
</style>