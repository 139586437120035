import { db } from "../firebase";

export const getVehiculoPorPatente = async (patente) => {
  try {
    const vehiculosRef = db.collection("vehiculos");
    const querySnapshot = await vehiculosRef
      .where("patente", "==", patente)
      .get();

    if (querySnapshot.empty) {
      return null;
    }

    const vehiculos = [];
    querySnapshot.forEach((doc) => {
      vehiculos.push({ id: doc.id, ...doc.data() });
    });

    return vehiculos[0];
  } catch (error) {
    throw error;
  }
};

export const getVehiculosEnAlta = async () => {
  const marcas = await getMarcas()
  let respuesta = await db.collection("vehiculos").get();
  let vehiculos = [];
  for (let index = 0; index < respuesta.docs.length; index++) {
    let vehiculo = respuesta.docs[index].data();
    vehiculo.id = respuesta.docs[index].id;
    if (vehiculo.estadoRef.id === "1") {
      vehiculo.marca = marcas.find(marc => marc.id == vehiculo.marcaRef.id)?.nombre
      vehiculos.push(vehiculo);
    }
  }
  return vehiculos;
};


const getMarcas = async () => {
  let respuesta = await db.collection("marcas").get();
  let marcas = [];
  for (let index = 0; index < respuesta.docs.length; index++) {
    let marca = respuesta.docs[index].data();
    marca.id = respuesta.docs[index].id;
    marcas.push(marca);
  }
  return marcas
}

export const getMarcasUsados = async () => {
  try {
    let marcas = []
    const querySnapshot = await db.collection('marcas').get();
    querySnapshot.forEach((doc) => {
      marcas.push({
        ...doc.data(),
        id: doc.id
      })
    });

    return marcas
  } catch (error) {
    return {
      resultado: 0,
      msj:
        "Ocurrio un problema al ejecutar el metodo getMarcasUsados: " +
        error.message,
    };
  }
}
