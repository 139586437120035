<template>
  <v-hover v-slot="{ hover }">
    <router-link :to="detalleVehiculoRoute">
      <v-card class="mx-auto card" max-width="330" :class="hover ? 'hovercard' : ''">
        <v-lazy
          v-model="isActive"
          :options="{ threshold: 0.5 }"
          min-height="200"
          transition="fade-transition"
        >
          <v-img :lazy-src="urlImagen" :src="urlImagen"></v-img>
        </v-lazy>
        <div class="chip">{{ vehiculo.marca }}</div>
        <v-card-title class="tituloCard">
          {{ vehiculo.marca }} {{ vehiculo.anio }} <br />
          {{ vehiculo.modeloBase }}
        </v-card-title>

        <v-card-text class="descripcion">
          {{ vehiculo.transmision }} - {{ vehiculo.km }}km -
          {{ vehiculo.motor }}
          <br />
          {{ vehiculo.modeloFull }}
          <br />
          PRECIO: {{ precioVehiculo }}
        </v-card-text>
        <v-col class="btn">
          <router-link :to="detalleVehiculoRoute">
            <v-btn color="light-blue accent-4" style="color: #f2f2f2"
              >MAS INFORMACION</v-btn
            >
          </router-link>
        </v-col>
      </v-card>
    </router-link>
  </v-hover>
</template>

<script>
import { format_money_round } from '../../util/utils'

export default {
  props: {
    vehiculo: Object,
    vehiculos: Array
  },
  data() {
    return {
      isActive: false
    }
  },

  created() {
    this.guardarListaLocalStorage(this.vehiculos)
  },

  computed: {
    detalleVehiculoRoute() {
      return {
        name: 'DetalleVehiculo',
        params: {
          vehiculo: this.vehiculo.patente,
          datos: this.vehiculo,
          lista_vehiculos: this.vehiculos
        }
      }
    },

    precioVehiculo() {
      return this.vehiculo ? format_money_round(this.vehiculo.precio) : ''
    },

    urlImagen() {
      return this.vehiculo && this.vehiculo.UrlImagenesMini[0]
        ? this.vehiculo.UrlImagenesMini[0]
        : '/sinImagenes.webp'
    }
  },

  methods: {
    guardarListaLocalStorage(lista) {
      localStorage.setItem('det_lista_vehiculo', JSON.stringify(lista))
    }
  }
}
</script>

<style>
.card {
  position: relative;
  transition: all ease 200ms;
  cursor: pointer;
}

a {
  text-decoration: none !important;
}

.hovercard {
  background: #d3dcdd !important;
  scale: 1.03;
  z-index: 1.5;
  &.titulo {
    color: #f2f2f2 !important;
  }

  &.descripcion {
    color: #f2f2f2 !important;
  }
}

.tituloCard {
  margin-top: -15px;
}

.chip {
  background: #1875c7;
  display: inline;
  padding: 2px 5px;
  border-radius: 5px;
  color: white;
  position: absolute;
  top: 12px;
  left: 12px;
  cursor: default;
  font-size: 13px;
  font-weight: 700;
}

.btn {
  margin-top: -10px !important;
}

.descripcion {
  margin-top: -10px !important;
}
</style>
