<template>
    
      <v-btn 
          class="primary mr-2 mt-5 mb-5" 
          @click="Test()"
      >
      Exportar
      <v-icon small class="ml-2">fas fa-file-download </v-icon>
      </v-btn>
</template>

<script>
import XLSX from 'xlsx'
import FileSaver from 'file-saver'
import moment from 'moment'

export default {
  props:[ 'valor' ],
  data() {
    return {
        
    }
  },
  methods: {
    Test(){
      var cabecera = [['Año' , 'Marca','Modelo','KM','Ubicacion','Patente','Color','Transmision','Motor','Combustible','Puertas','Precios','Empresa']];
      var ws = XLSX.utils.aoa_to_sheet(cabecera);
      var array = []
      this.valor.forEach(element => {
        let dato = {
          anio: element.anio,
          marca : element.marca,
          modelo : element.modelo,
          km:element.km,
          ubicacion:element.ubicacion,
          patente:element.patente,
          color:element.color,
          transmision : element.transmision,
          motor:element.motor,
          combustible:element.combustible,
          puertas:element.puertas,
          precio:element.precio,
          empresa:element.empresa
        }
        array.push(dato)
      });
       XLSX.utils.sheet_add_json(ws , array,{
        header : ["anio","marca","modelo","km","ubicacion","patente","color","transmision","motor","combustible","puertas","precio", "empresa"],
        skipHeader : true,
        origin : -1
      })
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws ,'SheetJS' );
      var wbout = XLSX.write(wb , {bookType:'xlsx',  type: 'array'}); 
      let name = 'Vehiculos_' +   moment().format() + '.xlsx'
      FileSaver.saveAs(new Blob([wbout],{type:"application/octet-stream"}), name);  
    }
  },
}
</script>

<style>

</style>