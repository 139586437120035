<template>
  <v-footer
      class="justify-center"
      color="black"
      max-height="600"
    >
    <v-container>
        <v-row>
          <v-col cols="12" md="6">
              <h3 class=" text--lighten-1 font-weight-light grey--text text-center" style="color:white; font-size:15px !important">CONTACTO</h3>
              <div align="center" justify="center" >
                  <v-row  justify="center" style="margin-top:0px" >
                      <p class="font-weight-light grey--text" style="color:#5c5c5c;font-size:13px !important"><v-icon color="#5c5c5c" size="13" style="margin-top:-4px ; margin-right:7px;">fas fa-map-marker-alt</v-icon>Salta (Av. Paraguay 2651)</p>
                  </v-row>
                  <v-row  justify="center">
                      <p  class="font-weight-light grey--text" style="color:#5c5c5c; margin-left:-50px; font-size:13px !important;margin-top:-10px"><v-icon color="#5c5c5c" size="13" style="margin-top:-4px ; margin-right:7px">fas fa-phone</v-icon>+54 387 5437540</p>
                  </v-row>
                  <v-row  justify="center">
                      <a href="mailto:dycarchevrolet@gmail.com?subject= " target="_blank" style="text-decoration: none;color:#5c5c5c;margin-left:0px;font-size:13px !important;margin-top:-12px" class="font-weight-light grey--text link"><v-icon color="#5c5c5c" size="13" style="margin-top:-4px ; margin-right:7px">fas fa-envelope</v-icon>dycarchevrolet@gmail.com</a>
                      <!-- <p   class="font-weight-light grey--text" style="color:#5c5c5c; margin-left:25px;font-size:13px !important;margin-top:-10px"><v-icon color="#5c5c5c" size="13" style="margin-top:-4px ; margin-right:7px">fas fa-envelope</v-icon>automotore4067@gmial.com</p> -->
                  </v-row>
                  
              </div>
          </v-col>
          <!-- <v-col cols="12" md="4">
              <h3 class="title text--lighten-1 font-weight-light grey--text text-center" style="color:white;font-size:15px !important">REDES SOCIALES</h3>
              <div align="center" justify="center"  >
                  <v-row justify="center" style="margin-top:-5px" >
                        <a href="https://www.facebook.com/4067automotores" target="_blank" style="text-decoration: none;color:#5c5c5c;margin-left:-10px;font-size:13px !important" class="font-weight-light grey--text link"><v-icon color="#5c5c5c" size="13" style="margin-top:-4px ; margin-right:7px">fab fa-facebook-square</v-icon>Dycar Usados</a>
                  </v-row>
                  <v-row  justify="center" style="margin-top:15px">
                        <a href="https://www.instagram.com/4067automotores/?hl=es-la" target="_blank" style="text-decoration: none;color:#5c5c5c; margin-left:-10px;font-size:13px !important" class="font-weight-light grey--text link"><v-icon color="#5c5c5c" size="13" style="margin-top:-4px ; margin-right:7px">fab fa-instagram</v-icon>Dycar Usados</a>
                  </v-row>           
              </div>
          </v-col> -->
          <v-col cols="12" md="6">
              <h3 class="title text--lighten-1 font-weight-light grey--text text-center" style="color:white;font-size:15px !important">LEGALES</h3>
              <div align="center" justify="center">
                 <p  class="font-weight-light grey--text" style="color:#5c5c5c;margin-left:-5px;font-size:13px !important"><v-icon color="#5c5c5c" size="8" style="margin-top:-4px ; margin-right:7px">fas fa-circle</v-icon>Términos y Condiciones</p>
                  <!-- <p  class="font-weight-light grey--text" style="color:#5c5c5c; margin-left:5px;font-size:13px !important;margin-top:-10px"><v-icon color="#5c5c5c" size="13" style="margin-top:-4px ; margin-right:7px">fas fa-ban</v-icon>Botón de arrepentimiento</p> -->
              </div>
          </v-col>
        </v-row>
    </v-container>
      <div class="title font-weight-light grey--text text--lighten-1 text-center" style="margin-top:-10px">
       <p style="font-size:12px;margin-bottom:-4px"> Copyright &copy; {{ (new Date()).getFullYear() }}  Todos los derechos reservados | Borigen Betzel Argentina</p>
      </div>
    </v-footer>
</template>

<script>
export default {
}
</script>

<style scoped>

/* a:hover{
    color: #2067b7 !important
} */

.link:hover{
    color: #2067b7 !important
}
</style>